import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { type Swiper as SwiperRef } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import BreadCrumbLink from 'components/front-end/BreadCrumbs/BreadCrumbLink'
import MainNavBack from 'components/front-end/Navigation/MainNavBack'
import { trackNavigation } from 'src/utils/tracking'
import { NavigationFields, NavigationItem } from '../Navigation/_interface'

import 'swiper/css'

import usePageContext from 'components/front-end/_layoutContext'

type BreadCrumbProps = {
  fields?: NavigationFields
}

const BreadCrumb = ({ fields }: BreadCrumbProps): JSX.Element => {
  const pageState = usePageContext().pageContext

  const router = useRouter()

  let pathName =
    Array.isArray(router?.query?.path) && router?.query?.path[0] !== 'en'
      ? '/' + (router?.query?.path as string[]).join('/').toLowerCase()
      : ''

  pathName = pathName.startsWith('/') ? pathName.substring(1, pathName.length) : pathName
  const path = pathName.split('/')

  const lastPath = path[path.length - 1]

  const [levelOne, setLevelOne] = useState<NavigationItem | null>()
  const [levelTwo, setLevelTwo] = useState<NavigationItem | null>()
  const [levelLast, setLevelLast] = useState<NavigationItem[]>([])
  const [navList, setNavList] = useState<NavigationItem[]>([])
  const [hideBreadcrumbs, setHideBreadcrumbs] = useState<boolean>(true)
  const [fadeBreadcrumbs, setFadeBreadcrumbs] = useState<boolean>(false)

  const [swiperElm, setSwiperElm] = useState<SwiperRef>()
  const [swipeMove, setSwipeMove] = useState<boolean>(false)

  useEffect(() => {
    const swiperPage = document.querySelector('[data-active="true"]') as HTMLDivElement
    if (swiperElm) swiperElm?.slideTo(Number(swiperPage?.dataset.index), 1000)
  }, [swiperElm])

  const [breadcrumbList, setBreadcrumbList] = useState<NavigationItem[] | undefined>([])

  // let breadcrumbList = fields?.data?.layout?.item.children.results

  // Check if hidden page is active route
  const buildBreadcrumbs = () => {
    let willHide = false
    breadcrumbList?.forEach((x) => {
      if (
        x.hideInNavigation?.value?.value &&
        ('/' + pathName?.toLowerCase()).includes(x.url?.path?.toLowerCase() as string)
      ) {
        willHide = true
      }
      x.children?.results?.forEach((y) => {
        if (
          y.hideInNavigation?.value?.value &&
          ('/' + pathName?.toLowerCase()).includes(y.url?.path?.toLowerCase() as string)
        ) {
          willHide = true
        }
        y.children?.results?.forEach((z) => {
          if (
            z.hideInNavigation?.value?.value &&
            ('/' + pathName?.toLowerCase()).includes(z.url?.path?.toLowerCase() as string)
          ) {
            willHide = true
          }
          z.children?.results?.forEach((a) => {
            if (
              a.hideInNavigation?.value?.value &&
              ('/' + pathName?.toLowerCase()).includes(a.url?.path?.toLowerCase() as string)
            ) {
              willHide = true
            }
          })
        })
      })
    })

    // if( true ) {
    //   willHide = true
    // }

    return willHide
  }

  useEffect(() => {
    setBreadcrumbList(fields?.data?.item?.children.results)
  }, [fields, pathName])

  useEffect(() => {
    if (path[0])
      setLevelOne(
        !buildBreadcrumbs()
          ? breadcrumbList?.find((x) => x.url?.path?.toLowerCase().includes(path[0].toLowerCase()))
          : null
      )
    // setLevelOne(breadcrumbList?.find((x) => x.url?.path?.toLowerCase().includes(path[0].toLowerCase())))
  }, [breadcrumbList, levelOne])

  // Fix to hide arrow on Parent if all Items are Hidden below
  const hasVisibleChildren = (itemList: any) => {
    if (!!itemList?.length && !itemList?.find((x: any) => x.hideInNavigation?.value?.value === true)) return true
    return false
  }

  useEffect(() => {
    let previousQueryPath: null | string = null
    let checkQueryPath: null | string = null

    const aniStart = (url: string) => {
      checkQueryPath = url.split('?')[0]
      if (previousQueryPath && previousQueryPath !== checkQueryPath) setFadeBreadcrumbs(true)
      previousQueryPath = checkQueryPath
      setFadeBreadcrumbs(true)
    }
    const aniEnd = () => {
      if (previousQueryPath && previousQueryPath !== checkQueryPath) {
        setLevelOne(null)
        setLevelTwo(null)
        setLevelLast([])
      }
      setFadeBreadcrumbs(false)
    }

    router.events.on('routeChangeStart', aniStart)
    router.events.on('routeChangeComplete', aniEnd)
    router.events.on('routeChangeError', aniEnd)
    return () => {
      router.events.off('routeChangeStart', aniStart)
      router.events.off('routeChangeComplete', aniEnd)
      router.events.off('routeChangeError', aniEnd)
    }
  }, [router.events])

  useEffect(() => {
    if (levelOne) {
      setLevelTwo(null)
      setNavList([levelOne])
      document.querySelector('header')?.classList.remove('no-breadcrumb')
    } else {
      setNavList([])
      if (!navList.length && !pageState?.history.isOwnPath) {
        document.querySelector('header')?.classList.add('no-breadcrumb')
      }
    }
    if (levelOne?.children?.results?.length) {
      setLevelLast(levelOne?.children?.results)
    }

    setHideBreadcrumbs(false)

    if (levelOne?.children?.results?.length) {
      if (path[1])
        setLevelTwo(
          levelOne?.children?.results?.find((x) => x.url?.path?.toLowerCase().includes(path[1].toLowerCase()))
        )
    }
  }, [levelOne])

  useEffect(() => {
    if (levelOne && levelTwo) {
      setNavList([levelOne, levelTwo])
    }
    if (levelTwo?.children?.results?.length && hasVisibleChildren(levelTwo?.children?.results)) {
      setLevelLast(levelTwo?.children?.results)
    }
  }, [levelTwo])

  useEffect(() => {
    if (levelOne) {
      if (levelTwo?.children?.results && hasVisibleChildren(levelTwo?.children?.results)) {
        setNavList([levelOne, levelTwo, ...levelLast])
        return
      }
      setNavList([levelOne, ...levelLast])
    }
  }, [levelLast])

  useEffect(() => {
    pageState?.breadcrumbs?.setIsVisibleBreadcrumbs(!!navList.length)
  }, [navList])

  const breadcrumbClasses = `
    ${hideBreadcrumbs || router.asPath === '/' ? 'hidden' : ''}
    bg-white [.lightx_&]:bg-white [.dark_&]:bg-black-off [.grey_&]:bg-black/60
    border-b
    transition-all duration-500
    ${
      !pageState?.header.isStickyHeader
        ? 'border-transparent'
        : 'page-up border-grey-light [.dark_&]:border-grey-medium '
    }
  `
  const containerClasses = `
    adf-breadcrumb pl-6 navigation-grid py-0 w-full
    flex items-baseline relative
    transition duration-500
    ${fadeBreadcrumbs ? 'opacity-0' : 'opacity-1'}
  `
  const gradClasses = `
    z-10 absolute bottom-0 right-0 h-full w-14
    bg-gradient-to-r from-gradient
    to-white [.light_&]:to-white [.dark_&]:to-black [.grey_&]:bg-black/60
    transition duration-200
    ${swipeMove ? 'opacity-0' : 'opacity-1'}
    xm:hidden pointer-events-none
  `
  const itemClasses = `
    h-16 xm:h-[70px] lg:h-[108px] x[.page-up_&]:xm:h-16 ${pageState?.header.isStickyHeader ? '!h-16' : ''}
    !flex items-center relative
    transition-all duration-[500ms] delay-0
  `
  const slideClasses = `
    pr-8 xm:first:pr-12
    [&:nth-child(1)>div]:block
    ${
      !!levelTwo && hasVisibleChildren(levelTwo?.children?.results)
        ? '[&:nth-child(2)>div]:block xm:[&:nth-child(2)]:pr-12'
        : ''
    }
  `
  const spacerClasses = `
    spacer hidden w-px h-[15px] bg-black [.dark_&]:bg-white [.grey_&]:bg-white absolute right-4 xm:right-6
    -translate-y-1/2 top-1/2
  `

  const isPathActive = (path: string | undefined) => {
    const splitPath = path ? path.split('/') : ''
    return splitPath[splitPath.length - 1].toLowerCase() === lastPath.toLowerCase()
  }

  return (
    <>
      <div className={breadcrumbClasses}>
        <div className={containerClasses}>
          {!!navList.length && (
            <Swiper
              className={`transparent-right-40 w-full`}
              slidesPerView={'auto'}
              spaceBetween={1}
              onTouchMove={() => setSwipeMove(true)}
              onTransitionEnd={() => setSwipeMove(false)}
              watchOverflow={true}
              onSwiper={(swiper) => setSwiperElm(swiper)}
            >
              {navList?.map((x, i) => (
                <SwiperSlide
                  data-active={isPathActive(x?.url?.path)}
                  data-index={i}
                  key={x.url?.path + '-' + i}
                  className={`${slideClasses} flex !w-fit items-center pr-4 ${
                    x.hideInBreadcrumb?.value?.value ? '!hidden' : ''
                  }`}
                >
                  <div className={`${itemClasses}`}>
                    <BreadCrumbLink
                      linkText={x.pageTitle?.value}
                      linkHref={x.url?.path}
                      isActive={isPathActive(x?.url?.path)}
                      onClick={() =>
                        trackNavigation({
                          location: 'header',
                          path: `breadcrumb - ${x.pageTitle?.value || ''}`,
                        })
                      }
                    />
                  </div>
                  <div className={spacerClasses}></div>
                </SwiperSlide>
              ))}
              <SwiperSlide className={`${itemClasses} !w-[200px]`}></SwiperSlide>
            </Swiper>
          )}
          {!navList.length && pageState?.history.isOwnPath && (
            <div className={`${itemClasses}`}>
              <MainNavBack
                className="[.dark_&]:text-white [.grey_&]:text-white"
                title={'Back'}
                onClick={() => {
                  router.back()
                  trackNavigation({
                    location: 'header',
                    path: 'breadcrumb - back',
                  })
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default BreadCrumb
