import { useRef, useState } from 'react'
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Icon from '../Icons/Icon'

type MainNavIconProps = {
  link?: LinkField
  title?: string
  icon?: StrokeIcons
  onClick?: () => void
  onMouseOver?: () => void
  isSelected?: boolean | undefined
  ariaExpanded?: boolean
  className?: string
}

const MainNavIcon = ({
  link,
  title = '',
  icon,
  onClick,
  isSelected = false,
  ariaExpanded,
  className,
}: MainNavIconProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const itemElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = itemElement?.current?.getBoundingClientRect().left || 0
    const width = itemElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < width / 2) finalX = 0
    if (finalX > width / 2) finalX = width

    setMousePosition({ left: finalX })
  }

  // const [clicked, setClicked] = useState(false)

  const mainNavIconClasses = `
        group/icon cursor-pointer relative z-0
        xl:w-16 h-16 [@media(min-width:1700px)]:w-22 xl:h-22
        px-4 [@media(min-width:1700px)]:px-6
        flex items-center justify-center
        [.light_&]:text-grey-medium text-white
        hover:!text-white [.light_&]:hover:!text-white
        transition duration-300
        [&_.svg-clicked]:pointer-events-none
        focus-visible:ring-0 focus-visible:ring-offset-0
        before:block before:absolute before:inset-0 before:transition-[outline] before:outline before:outline-0 before:outline-[--focus-color]
        focus-visible:before:outline-3
        ${
          isSelected
            ? 'svg-clicked clicked-active !text-white'
            : '[.dark_&]:hover:!text-black-off [.grey_&]:hover:!text-black-off'
        }
        ${className}
    `
  const fillClasses = `
        tab-fill z-[-1] absolute top-0 left-0 h-full w-full
        bg-black [.light_&]:bg-black [.dark_&]:bg-white [.grey_&]:bg-white
        scale-x-0 group-hover/icon:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-[400] ease-linear
        ${isSelected ? 'scale-x-100' : ''}
    `

  return (
    <Link
      href={(link?.value?.href as string) || ''}
      ref={itemElement}
      tabIndex={0}
      className={`
        ${mainNavIconClasses}
        ${isSelected ? 'nav-open' : 'nav-closed'}
      `}
      onMouseMove={(ev) => handleMouseMove(ev)}
      onClick={(event) => {
        if (!link?.value?.href) {
          event?.preventDefault()
        }
        onClick?.()
      }}
      aria-label={title || link?.value?.text}
      aria-expanded={ariaExpanded}
    >
      {icon ? <Icon name={icon} type="stroke" clicked /> : null}
      <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
    </Link>
  )
}

export default MainNavIcon
