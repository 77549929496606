module.exports = {
  screensRem: {
    xs: 23.4375,
    sm: 26.75,
    md: 48,
    xm: 64,
    lg: 80,
    xl: 90,
    xlg: 106.25,
    xxl: 120,
  },
};
