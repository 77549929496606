import { ElementType, ReactElement, useRef, useState } from 'react'
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs'
import Link, { LinkProps } from 'next/link'

import { trackNavigation } from 'src/utils/tracking'
import { NavigationAction } from './_interface'

export type MainNavActionProps = Omit<LinkProps, 'href'> & {
  link: LinkField
  item?: NavigationAction
  type?: string
  onMouseOver?: () => void
  isExperience?: boolean
  isSelected?: boolean
  onClick?: () => void
  count?: number
} & (
    | ({
        button: true
      } & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'disabled'>)
    | ({
        button?: never
      } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>)
  )

// @todo remove forwardRef unless we need it (currently unused)
const MainNavAction = ({
  link,
  onMouseOver,
  isSelected,
  onClick,
  count = 0,
  button,
  className,
  ...rest
}: MainNavActionProps): MainNavActionProps['button'] extends true
  ? ReactElement<HTMLButtonElement>
  : ReactElement<HTMLAnchorElement> => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const As = (button ? 'button' : Link) as ElementType

  const itemElement = useRef<HTMLAnchorElement | HTMLButtonElement>(null)

  function handleMouseMove(
    ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    const offsetLeft = itemElement?.current?.getBoundingClientRect().left || 0
    const width = itemElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < width / 2) finalX = 0
    if (finalX > width / 2) finalX = width

    setMousePosition({ left: finalX })
  }

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {nav: 1700} JS only
  // {xxl: 1920}

  const itemClasses = `
        main-nav-item group relative flex items-center whitespace-nowrap
        *:aria-disabled:opacity-25
        transition duration-300
        focus-visible:ring-0 focus-visible:ring-offset-0
        before:block before:absolute before:inset-0 before:transition-[outline] before:outline before:outline-0 before:outline-[--focus-color]
        focus-visible:before:outline-3
        ${isSelected ? 'is-active' : ''}
        ${className}
        `
  const titleClasses = `
        mnav uppercase px-4 xl:px-[18px] [@media(min-width:1700px)]:px-6 z-[1] ${
          !!count ? 'w-auto' : 'w-full'
        } text-center
        group-hover:text-white [.light_&]:text-black-off [.light_&]:group-hover:text-white [.dark_&]:group-hover:text-black-off [.grey_&]:group-hover:text-black-off
        transition duration-300 text-white
    `
  const fillClasses = `
        tab-fill z-0 absolute top-0 left-0 h-full w-full
        bg-black-off [.light_&]:bg-black-off [.dark_&]:bg-white [.grey_&]:bg-white
        scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
        transition duration-[400] ease-linear
    `
  const countClasses = `
        indicator text-sm z-[1] mr-4 -ml-2 xl:-ml-[10px]
        h-[23px] w-[23px] flex shrink-0
        items-center justify-center rounded-full
        font-uniform-condensed font-[500]
        text-black no-underline bg-tri-service
        transition duration-500
        [.light_&]:group-hover:bg-white [.light_&]:group-hover:text-black
        [.dark_&]:group-hover:bg-black [.dark_&]:group-hover:text-white
    `
  return (
    <As
      ref={itemElement}
      onMouseMove={(
        ev: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      ) => handleMouseMove(ev)}
      href={(link?.value?.href as string) || ''}
      target={link?.value?.target}
      tabIndex={0}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      className={itemClasses}
      prefetch={undefined}
      onClick={() => {
        onClick?.()
        trackNavigation({
          location: 'header',
          path: link?.value?.text || 'navigation action',
        })
      }}
      {...rest}
    >
      <span className="relative flex h-full w-full items-center justify-center">
        <span className={titleClasses}>
          <Text field={{ value: link.value.text }} />
        </span>
        {!!count && <span className={countClasses}>{count}</span>}
        {/* )} */}
        <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
      </span>
    </As>
  )
}

export default MainNavAction
