import { useEffect, useState } from 'react'

import Icon from '../Icons/Icon'

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    autofocus?: string
  }
}

type MainNavBackProps = {
  title: string
  onMouseOver?: () => void
  onClick?: () => void
  className?: string
}

const MainNavBack = ({ title, onClick, className = '' }: MainNavBackProps): JSX.Element => {
  // Icon wouldn't always load without a forced update
  const [icon, setIcon] = useState(false)

  useEffect(() => {
    setIcon(!!title)
    const autofocus = document.querySelector('.' + slugify(title)) as HTMLButtonElement
    if (autofocus) autofocus.focus()
  }, [title])

  const slugify = (text: string) => {
    return text
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
  }

  const itemClasses = `
        main-nav-item group relative h-10 w-full px-0
        flex items-center
        snav text-black
        transition duration-300
        focus:outline-none visited:outline-none focus-visible:!z-40
        ${slugify(title)}
        ${className}
    `
  const titleClasses = `
        z-[1] ml-2 whitespace-nowrap overflow-hidden text-ellipsis
        transition duration-300
    `
  return (
    <button
      className={itemClasses}
      onClick={() => onClick?.()}
      aria-label={`Back to ${title}`}
      type="button"
      autoFocus={true}
    >
      {icon && <Icon name="arrow-west" type="stroke" width={20} height={20} className="shrink-0" />}
      <span className={titleClasses}>{title}</span>
    </button>
  )
}

export default MainNavBack
