import React, { KeyboardEvent, useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'

import { FacetType } from 'components/ADFSearchResults'
import { extractUrlValue } from 'src/utils/regexp'
import Icon from '../Icons/Icon'
import SearchInput from './SearchInput'
import SearchInputResults from './SearchInputResults'

interface SearchMatchDataProps {
  TotalCount: number
  Results?: string[]
  Message: null | string
}
interface SearchFieldProps {
  degreeOptions?: Array<FacetType>
  filter?: number
  focusField?: boolean
  inPage?: boolean
  isEventSearch?: boolean
  isJobSearch?: boolean
  location?: boolean
  onclickFilter?: () => void
  onLocationClick?: () => void
  onQueryClick?: (result: string) => void
  onResetClick?: () => void
  placeholderText?: string
  queryList?: SearchMatchDataProps | null
  queryPath?: string
  showTotal?: boolean
  routeQuery: string
  searchPos?: boolean
  searchTitle?: string
  showDegrees?: boolean
  updateResults?: (query: string, selectedDegree: string) => void
  searchClicked?: (query: string) => void
  onClickTotal?: (query: string, seeAllQuerySuggestion: string) => void
  className?: string
}

const SearchField = ({
  routeQuery,
  focusField,
  inPage = false,
  filter,
  location = false,
  searchPos = false,
  onclickFilter,
  updateResults,
  searchClicked,
  searchTitle = 'Search',
  placeholderText = 'Start typing',
  onLocationClick,
  queryList,
  queryPath,
  showTotal,
  onQueryClick,
  onResetClick,
  showDegrees,
  onClickTotal,
  className,
}: SearchFieldProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const router = useRouter()

  useEffect(() => {
    const field = document?.querySelector('.search-input') as HTMLInputElement
    setTimeout(() => field.focus(), 100)
  }, [focusField])

  const initialQuery = extractUrlValue(routeQuery, 'query') ?? ''
  const [inputQuery, setInputQuery] = useState(initialQuery)

  useEffect(() => {
    const newQuery = extractUrlValue(routeQuery, 'query') ?? ''
    setInputQuery(newQuery)
  }, [routeQuery])

  const handleClickResult = (result: string) => {
    setInputQuery(result)

    if (onQueryClick) {
      onQueryClick(result)
    }

    if (updateResults && !sitecoreContext?.pageEditing) {
      updateResults(result, showDegrees ? inputQuery : '')
    }
  }

  // font: font-uniform-condensed font-[500] text-sm
  const counterClasses = `
    rounded-full text-black mtag h-[21px] w-[21px] items-center justify-center flex no-underline group-hover:bg-black group-hover:text-white transition duration-500
    [.tri-service_&]:bg-tri-service [.army_&]:bg-army [.air-force_&]:bg-air-force [.navy_&]:bg-navy
    ml-[3px] mr-px
    `

  function updateSearch(value: string) {
    setInputQuery(value)

    if (updateResults && !sitecoreContext?.pageEditing) {
      updateResults(value, showDegrees ? inputQuery : '')
    }
  }

  //if query contains more keywords, show only first keyword in the input
  function handleSearchInputValue(query: string) {
    const seeAllParamInput = new URLSearchParams(routeQuery).get('inputQuery')
    const isSeeAllTrue = !!seeAllParamInput

    if (isSeeAllTrue && query.includes(',')) {
      const firstKeyword = query.split(',')[0].trim()
      return seeAllParamInput ?? firstKeyword
    }
    return query
  }

  return (
    <>
      <div className={twMerge('relative flex w-full flex-col gap-y-2.5 md:gap-y-0', className)}>
        <SearchInput
          label={searchTitle}
          placeholder={placeholderText}
          value={handleSearchInputValue(inputQuery)}
          onChange={updateSearch}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
              searchClicked?.(inputQuery)
              router.push({ query: { ...router.query, query: inputQuery, page: 1 } }, undefined, {
                shallow: true,
              })
            }
          }}
          searchPos={searchPos}
          onclickFilter={onclickFilter}
          onLocationClick={onLocationClick}
          resetClicked={() => onResetClick?.()}
          inPage={inPage}
          filter={filter}
          location={location}
        />

        <SearchInputResults
          query={inputQuery}
          results={queryList}
          path={queryPath}
          showTotal={showTotal}
          onClick={handleClickResult}
          onClickTotal={(e) => {
            if (onClickTotal) {
              e.preventDefault()
              //onClickTotal(queryList?.Results?.join(', ') ?? inputQuery, inputQuery)
              onClickTotal(inputQuery, inputQuery)
            }
          }}
        />
      </div>

      {!showDegrees && inPage && (
        <div className="sub-filter flex pb-4 pt-6 transition-all md:pb-8 xm:hidden">
          {filter !== undefined && (
            <>
              <button
                className="svg-hover flex items-center justify-center bg-white"
                onClick={() => {
                  if (onclickFilter) onclickFilter()
                }}
              >
                <Icon name="filter" type="stroke" className="h-[18px] w-[18px]" />
                {searchPos && filter > 0 && <span className={counterClasses}>{filter}</span>}
                <span className="mbody ml-1.5 font-normal">Filters</span>
              </button>
            </>
          )}
          {location && (
            <>
              <div className="bar mx-3 h-5 w-px border-r-[0.5px] border-grey"></div>
              <button
                className="svg-hover flex items-center justify-center bg-white"
                onClick={() => {
                  if (onLocationClick) onLocationClick()
                }}
              >
                <Icon name="location" type="stroke" className="h-[18px] w-[18px]" />
                <span className="mbody ml-1.5 font-normal">Location</span>
              </button>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SearchField
