import { useEffect, useState } from 'react'
import { z } from 'zod'

// Hook to listen to the `storage` change event and return any changes to the 'ADF-compare' key
export function useLocalStorage<T = string | null>(
  name: string,
  parser?: (value: string | null) => T,
  initialValue?: T
) {
  const [storedValue, setStoredValue] = useState<T | undefined>(initialValue)

  useEffect(() => {
    const handleStorageChange = ({ key, newValue, oldValue }: StorageEvent) => {
      if (key === name && newValue !== oldValue) {
        try {
          if (parser) {
            setStoredValue(parser(newValue))
          } else {
            setStoredValue(newValue as T)
          }
        } catch (error) {
          console.log(error)
          setStoredValue(initialValue)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [initialValue, name, parser])

  return storedValue
}

export const stringArrayParser = (value: string | null): string[] | null =>
  z
    .array(z.string().uuid())
    .nullable()
    .catch(null)
    .parse(value ? JSON.parse(value) : value)
