import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs'

export function fixAssetHref({ href = '', linktype }: LinkFieldValue, download = false) {
  if (download && !href.includes(`${process.env.HUB_API_HOST}`)) {
    return `${process.env.HUB_API_HOST}${href}`
  }

  const pdfViewerPath = '/pdf-viewer?pdf='
  const shouldUseViewer =
    !download && href && linktype === 'media' && !href.includes(pdfViewerPath) && href.includes('.pdf')
  return shouldUseViewer ? `${process.env.HUB_API_HOST}${pdfViewerPath}${href}?view` : href
}
