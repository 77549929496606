'use client'

import React, { ElementType, forwardRef, ReactElement, useEffect, useState } from 'react'
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import Icon from 'components/front-end/Icons/Icon'
import { FillIcons, StrokeIcons } from 'components/front-end/Icons/icon-data'
import { JobType, trackButton, trackJobButton } from 'src/utils/tracking'
import { ServiceString, useService } from 'src/utils/useService'

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'action'
  | 'tertiary'
  | 'tertiary-action'
  | 'primary-icon'
  | 'secondary-icon'
  | 'small-primary'
  | 'small-secondary'
  | 'small-action'

const isBrowser = true,
  isMobile = true
const color = {
  'tri-service': 'bg-tri-service',
  army: 'bg-army',
  'air-force': 'bg-air-force',
  navy: 'bg-navy',
  black: 'bg-black',
  white: 'bg-white',
}
const colorBorder = {
  'tri-service': 'border-tri-service',
  army: 'border-army',
  'air-force': 'border-air-force',
  navy: 'border-navy',
  black: 'border-black',
  white: 'border-black',
}
const colorBorderHover = {
  'tri-service': `${
    isBrowser
      ? 'onlyhover:hover:border-tri-service [.light_.dark_&]:onlyhover:hover:border-tri-service [.dark_&]:onlyhover:hover:border-tri-service'
      : ''
  } [&.mobhover]:border-tri-service [.light_.dark_&]:[&.mobhover]:border-tri-service [.dark_&]:[&.mobhover]:border-tri-service`,
  army: `${
    isBrowser
      ? 'onlyhover:hover:border-army [.light_.dark_&]:onlyhover:hover:border-army [.dark_&]:onlyhover:hover:border-army'
      : ''
  } [&.mobhover]:border-army [.light_.dark_&]:[&.mobhover]:border-army [.dark_&]:[&.mobhover]:border-army`,
  'air-force': `${
    isBrowser
      ? 'onlyhover:hover:border-air-force [.light_.dark_&]:onlyhover:hover:border-air-force [.dark_&]:onlyhover:hover:border-air-force'
      : ''
  } [&.mobhover]:border-air-force [.light_.dark_&]:[&.mobhover]:border-air-force [.dark_&]:[&.mobhover]:border-air-force`,
  navy: `${
    isBrowser
      ? 'onlyhover:hover:border-navy [.light_.dark_&]:onlyhover:hover:border-navy [.dark_&]:onlyhover:hover:border-navy'
      : ''
  } [&.mobhover]:border-navy [.light_.dark_&]:[&.mobhover]:border-navy [.dark_&]:[&.mobhover]:border-navy`,
  black: `${
    isBrowser
      ? 'onlyhover:hover:border-black [.light_.dark_&]:onlyhover:hover:border-black [.dark_&]:onlyhover:hover:border-black'
      : ''
  } [&.mobhover]:border-black [.light_.dark_&]:[&.mobhover]:border-black [.dark_&]:[&.mobhover]:border-black`,
  white: `${
    isBrowser
      ? 'onlyhover:hover:border-white [.light_.dark_&]:onlyhover:hover:border-white [.dark_&]:onlyhover:hover:border-white'
      : ''
  } [&.mobhover]:border-white [.light_.dark_&]:[&.mobhover]:border-white [.dark_&]:[&.mobhover]:border-white`,
}

export type ButtonProps = {
  'data-trackingid': string
  'data-trackinglabel'?: string
  'data-location'?: string
  'data-jobname'?: string
  'data-jobtype'?: JobType
  link: LinkField | never
  iframeLink?: LinkField
  videoLink?: string
  type?: ButtonTypes
  icon?: FillIcons | StrokeIcons
  iconHeight?: number
  iconWidth?: number
  service?: ServiceString | 'black' | 'white'
  isSmall?: boolean
  disabled?: boolean
  indicatorCount?: number
  solid?: boolean
} & (
  | ({
      button: true
      buttonType?: 'button' | 'submit' | 'reset'
    } & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'disabled'>)
  | ({
      button?: never
    } & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>)
) &
  ({ videoId: string; videoLabel: string } | { videoId?: never; videoLabel?: never })

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      'data-trackingid': trackingId,
      'data-trackinglabel': trackingLabel,
      link,
      icon,
      iconHeight,
      iconWidth,
      isSmall = false,
      onClick,
      button,
      type = 'primary',
      disabled = false,
      indicatorCount = 0,
      className = '',
      solid = false,
      service,
      tabIndex = 0,
      ...props
    }: ButtonProps,
    ref
  ): ButtonProps['button'] extends true ? ReactElement<HTMLButtonElement> : ReactElement<HTMLAnchorElement> => {
    const As = (button ? 'button' : Link) as ElementType
    let buttonType: 'button' | 'submit' | 'reset' | undefined = undefined

    if (button && 'buttonType' in props) {
      buttonType = props.buttonType
      delete props.buttonType
    }
    const [isHover, setIsHover] = useState(false)
    const [isDisabled, setIsDisabled] = useState(disabled)

    useEffect(() => {
      setIsDisabled(disabled)
    }, [disabled])

    service = useService(service)
    const isPrimary = type.includes('primary')
    const isSecondary = type.includes('secondary')
    const isTertiary = type.includes('tertiary')
    const isAction = type.includes('action')
    const isIcon = type.includes('icon')
    isSmall = type.includes('small') ? true : isSmall

    const descriptiveText = link?.value?.text || props['aria-label']

    const buttonFill = `absolute inset-0 transition duration-500`
    const buttonSize = isSmall ? `h-11` : isIcon ? `size-[92px]` : `h-[60px]`
    const buttonPad = isSmall ? `px-4` : isIcon ? `w-full` : `px-6`
    const buttonDisabled = `pointer-events-none ` + (isPrimary ? `text-grey-medium` : `text-grey-dark`)

    const lightTextPrimary = `text-black [.light_&]:text-black [.dark_&]:text-black [.light_.dark_&]:text-black ${
      isBrowser
        ? '[.light_&]:onlyhover:group-hover:text-black [.dark_&]:onlyhover:group-hover:text-white [.light_.dark_&]:onlyhover:group-hover:text-white'
        : ''
    } [.mobhover_&]:text-black`
    const lightTextSecondary = `text-black [.light_&]:text-black [.dark_&]:text-white [.light_.dark_&]:text-white`
    const lightTextTertiary = `text-black [.light_&]:text-black [.dark_&]:text-white [.light_.dark_&]:text-white`
    const lightTextAction = `text-black [.light_&]:text-black [.dark_&]:text-white [.light_.dark_&]:text-white ${
      isBrowser ? '[.dark_&]:onlyhover:hover:text-black [.light_.dark_&]:onlyhover:hover:text-black' : ''
    } [.dark_&]:[&.mobhover]:text-black [.light_.dark_&]:[&.mobhover]:text-black`
    const lightBorderSecondary = `border-black [.light_&]:border-black [.dark_&]:border-white [.light_.dark_&]:border-white`
    const buttonDisabledPrimary = `pointer-events-none text-grey-medium border-grey-light bg-grey-light  [.light_&]:text-grey-medium [.light_&]:border-grey-light [.light_&]:bg-grey-light   [.dark_&]:pointer-events-none [.dark_&]:text-black-off [.dark_&]:border-grey [.dark_&]:bg-grey`
    const buttonDisabledSecondary = `pointer-events-none text-grey-medium border-grey-medium`

    const textOrder = isAction ? `order-2 ${icon ? 'pl-0' : ''}` : 'order-1'
    const iconOrder = isAction ? 'order-1' : 'order-2'

    const iconSize = isIcon ? (isPrimary ? 44 : 32) : 15

    const iconColorClass = () => {
      if (isDisabled) return `text-gray-medium`
      if (isTertiary) return `text-black [.dark_&]:text-white [.light_.dark_&]:text-white`
      if (!isPrimary)
        return `[.light_&]:text-black [.dark_&]:text-white [.light_.dark_&]:text-white ${
          isBrowser
            ? '[.dark_&]:onlyhover:group-hover:text-black [.light_.dark_&]:onlyhover:group-hover:text-black'
            : ''
        } [.mobhover_&]:!text-black`
      if (service === 'black')
        return `text-white ${isBrowser ? 'onlyhover:group-hover:text-black' : ''} [.mobhover_&]:text-black`
      return `text-black`
    }

    useEffect(() => {
      process?.env?.NODE_ENV === 'development' &&
        isIcon &&
        !descriptiveText &&
        console.warn('Buttons require descriptive text')
    }, [isIcon, descriptiveText])

    const buttonMain = () => {
      let classes = `button-adf relative group ${buttonSize} max-w-[310px] inline-flex items-center border border-solid transition duration-0 delay-0`
      if (isPrimary)
        classes += isDisabled ? ` ${buttonDisabledPrimary}` : ` ${colorBorder[service as keyof typeof colorBorder]}`
      if (isSecondary)
        classes += isDisabled
          ? ` ${buttonDisabledSecondary}`
          : ` ${lightTextSecondary} ${lightBorderSecondary} ${
              colorBorderHover[service as keyof typeof colorBorderHover]
            }`
      if (isAction)
        classes += isDisabled
          ? ` ${buttonDisabledSecondary}`
          : ` ${lightTextAction} ${lightBorderSecondary} ${colorBorderHover[service as keyof typeof colorBorderHover]}`
      if (isIcon) classes += isPrimary ? ` bg-white border-none` : ``
      if (isTertiary)
        return (
          `button-adf relative group inline-flex items-center max-w-[310px]` +
          (isDisabled ? ` pointer-events-none text-grey-medium` : ` ${lightTextTertiary}`)
        )
      return classes
    }

    const getTextClasses = () => {
      let classes = `button-text ${buttonPad} ${textOrder} relative uppercase font-uniform-condensed font-[500] text-sm h-full flex items-center justify-center whitespace-nowrap overflow-hidden transition duration-500 delay-0`
      if (isPrimary) classes += ` ${lightTextPrimary}`
      if (isTertiary)
        return `button-text ${textOrder} font-uniform-condensed uppercase font-uniform-condensed font-[500] text-sm flex items-center whitespace-nowrap overflow-hidden`
      return classes
    }
    const getTextFillClasses = () => {
      let classes = `button-fill ${buttonFill} z-0 origin-right scale-x-100`
      if (isPrimary)
        classes += isDisabled
          ? ` ${buttonDisabledPrimary}`
          : ` ${color[service as keyof typeof color]} ${
              isBrowser ? 'onlyhover:group-hover:scale-x-0' : ''
            } [.mobhover_&]:scale-x-0`
      if (isSecondary) classes += ` transparent`
      if (isTertiary) return `hidden`
      return classes
    }
    const getIconClasses = () => {
      let classes = `button-icon ${buttonPad} ${iconOrder} relative h-full flex items-center justify-center whitespace-nowrap`
      if (isAction && icon) classes += `pl-6 pr-[8px]`
      if (isAction && !icon) classes += ` pl-0`
      if (isTertiary) return `button-icon ${iconOrder} relative items-center` + (isAction ? ` pr-1.5` : ` pl-2`)
      return classes
    }

    const getIconFillClasses = () => {
      let classes = `button-fill ${buttonFill} z-0 origin-left`
      if (isPrimary)
        classes += isDisabled ? ` ${buttonDisabled}` : ` ${color[service as keyof typeof color]} scale-x-100`
      if (isSecondary)
        classes += ` ${color[service as keyof typeof color]} scale-x-0 ${
          isBrowser ? 'onlyhover:group-hover:scale-x-100' : ''
        } [.mobhover_&]:scale-x-100`
      if (isIcon)
        classes += isPrimary
          ? ` origin-right ${isBrowser ? 'onlyhover:group-hover:scale-x-0' : ''} [.mobhover_&]:scale-x-0`
          : ` ${isBrowser ? 'onlyhover:group-hover:scale-x-100' : ''} [.mobhover_&]:scale-x-100`
      return classes
    }

    const getActionFillClasses = () => {
      let classes = `button-fill ${buttonFill} origin-left scale-x-0`
      if (isAction)
        classes += ` ${color[service as keyof typeof color]} ${
          isBrowser ? 'onlyhover:group-hover:scale-x-100' : ''
        } [.mobhover_&]:scale-x-100`
      return classes
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => {
      const title = trackingLabel || descriptiveText
      if (trackingId && title) {
        const payload = { title, guid: trackingId }

        if (props['data-jobname']) {
          // Tracking job card button click event
          trackJobButton({
            ...payload,
            jobName: props['data-jobname'],
            jobType: props['data-jobtype'],
            location: new URLSearchParams(window.location.search).get('tab') || '',
          })
        } else if (props['data-location']) {
          trackButton({ ...payload, location: props['data-location'] })
        } else {
          // Tracking regular button click event
          trackButton(payload)
        }
      }
      onClick?.(e)
    }

    //todo: this is a temporary to resolve phone link rendering issue.
    const linkUrl = link?.value?.href?.startsWith('http://tel:')
      ? link?.value?.href?.replace('http://tel:', 'tel:')
      : (link?.value?.href as string)

    // Remove invalid element attributes before the remaining props can be spread onto the element
    const {
      // iconHeight: _iconHeight,
      // iconWidth: _iconWidth,
      iframeLink: _iframeLink,
      videoLink: _videoLink,
      ...validAttributes
    } = props

    return (
      <As
        className={twMerge(
          type,
          service ? (service as string) : '',
          buttonMain(),
          `icon-hover-${isHover}`,
          disabled && 'disabled',
          className
        )}
        ref={ref}
        type={buttonType}
        tabIndex={tabIndex}
        href={linkUrl}
        target={link?.value?.target}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleClick}
        onTouchStart={(event: TouchEvent) => {
          if (isMobile) {
            const target = event.currentTarget as HTMLElement
            target?.classList.add('mobhover')
            setTimeout(() => target?.classList.remove('mobhover'), 500)
          }
        }}
        aria-disabled={disabled}
        aria-label={link?.value?.text}
        data-trackingid={trackingId}
        {...validAttributes}
      >
        {!isIcon && (
          <span className={getTextClasses()}>
            <span className="button z-[1] overflow-hidden text-ellipsis">{link?.value?.text}</span>
            {!isTertiary && <span className={getTextFillClasses()}></span>}
          </span>
        )}

        {icon && (
          <span className={getIconClasses()}>
            <span className="z-[1]">
              <Icon
                name={icon}
                type={solid ? 'fill' : 'stroke'}
                hover
                width={iconWidth ?? iconSize}
                height={iconHeight ?? iconSize}
                className={iconColorClass()}
              />
            </span>
            {indicatorCount > 0 && (
              <span
                className={`indicator text-sm z-[1] order-3 ml-1 flex h-[23px] w-[23px] items-center justify-center rounded-full font-uniform-condensed font-[500] text-black no-underline ${
                  color[service as keyof typeof color]
                } transition duration-500 group-hover:bg-black group-hover:text-white`}
              >
                {indicatorCount}
              </span>
            )}
            {isIcon && <span className="sr-only">{link?.value?.text}</span>}
            {!isTertiary && <span className={getIconFillClasses()}></span>}
          </span>
        )}

        {!isTertiary && <span className={getActionFillClasses()}></span>}
        {isTertiary && (
          <span
            className={`button-line absolute -bottom-0.5 left-0 mt-1 h-[1.5px] w-full origin-left scale-x-0 bg-black transition duration-500 [.dark_&]:bg-white ${
              isBrowser ? 'onlyhover:group-hover:scale-x-100' : ''
            } [.mobhover_&]:scale-x-100`}
          ></span>
        )}
      </As>
    )
  }
)
Button.displayName = 'Button'
export default Button

