import { useService } from 'src/utils/useService'

export type DateTagProps = {
  className?: string
  dateString?: string
  day?: number
  month?: number
  service?: string
} & ({ dateString: string; day?: never; month?: never } | { dateString?: never; day: number; month: number }) // If day and month are provided, dateString should not be provided

const monthDisplayName = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

const dateStringToDay = (date: string) => {
  const [y, m, d] = date.split('T')[0].split('-')
  return {
    day: d,
    month: monthDisplayName[parseInt(m) as keyof typeof monthDisplayName],
  }
}

const color = {
  'tri-service': 'bg-tri-service',
  army: 'bg-army',
  'air-force': 'bg-air-force',
  navy: 'bg-navy',
}

const DateTag = (props: DateTagProps): JSX.Element => {
  const date = props.dateString
    ? dateStringToDay(props.dateString)
    : { day: props.day, month: monthDisplayName[props.month as keyof typeof monthDisplayName] }

  const service = useService(props.service)

  return (
    <div
      className={`${
        color[service as keyof typeof color]
      } flex h-16 w-16 flex-col items-center justify-center text-black ${props.className}`}
    >
      <div className="meta pb-[3px]">{date.day}</div>
      <div className={'xstag'}>{date.month}</div>
    </div>
  )
}

export default DateTag
