import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import { StrokeIcons } from 'components/front-end/Icons/icon-data'
import Icon from '../Icons/Icon'

type MainNavCallProps = {
  link?: LinkField
  title?: string
  number?: string
  path?: string
  icon?: StrokeIcons
  onClick?: () => void
  isExperience?: boolean
  isSelected?: boolean
}

const MainNavItem = ({ link, number, icon = 'phone', onClick }: MainNavCallProps): JSX.Element => {
  const itemClasses = `
        main-nav-item group relative h-16 flex items-center px-0
        transition duration-300
    `
  const titleClasses = `
        px-5 z-[1]
        text-black
        transition duration-300
    `
  return (
    <Link href={(link?.value?.href as string) || ''} onClick={() => onClick?.()} className={itemClasses}>
      <span className="relative flex h-full w-full items-center text-black">
        <Icon name={icon} type="stroke" width={30} height={30} />
        <span className={titleClasses}>
          {link?.value?.text} <strong className={`font-semibold`}>{number}</strong>
        </span>
      </span>
    </Link>
  )
}

export default MainNavItem
