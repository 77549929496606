import { useEffect, useRef } from 'react'

export type ScrollAxis = 'vertical' | 'horizontal'
export type ScrollDirection = 'up' | 'down' | 'left' | 'right'
type UseScrollProgressCallback = (args: { direction: ScrollDirection; milestone: number; progress: number }) => void

const DEFAULT_MILESTONES = [0, 25, 50, 75, 100]

const inRange = (value: number, [lowest, highest]: [number, number]) => value > lowest && value < highest

const calculateScrollProgress = (el: HTMLDivElement, axis: ScrollAxis = 'vertical') =>
  Math.round(
    (axis === 'vertical'
      ? (el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100
      : (el.scrollLeft / (el.scrollWidth - el.clientWidth)) * 100) || 0
  )

const calculateMilestone = (value: number, current: number, milestones = DEFAULT_MILESTONES) => {
  if (milestones.includes(value)) {
    return value
  }

  const currentIndex = milestones.indexOf(current)
  const currentRange = [
    milestones[Math.max(0, currentIndex - 1)],
    milestones[Math.min(milestones.length - 1, currentIndex + 1)],
  ] as [number, number]

  if (inRange(value, currentRange)) {
    return current
  }

  return (
    milestones.slice(1, -1).find((_, i) => {
      const range: [number, number] = [milestones[i], milestones[i + 2]]

      // Exclude current milestone range
      if (inRange(current, range)) {
        return false
      }

      return inRange(value, range)
    }) ?? milestones[0]
  )
}

export function useScrollProgress(
  callback: UseScrollProgressCallback,
  config: { axis?: ScrollAxis; milestones?: number[] } = {}
) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const previousMilestone = useRef<number | undefined>()

  const { axis = 'vertical', milestones = DEFAULT_MILESTONES } = config

  // On mount
  useEffect(() => {
    const containerEl = containerRef.current

    if (containerEl && typeof previousMilestone.current === 'undefined') {
      previousMilestone.current = calculateMilestone(calculateScrollProgress(containerEl), 0)
    }
  }, [])

  // On scroll
  useEffect(() => {
    const containerEl = containerRef.current

    if (!containerEl) return

    const handleScroll = () => {
      const current = previousMilestone.current

      if (typeof current === 'undefined') {
        return
      }

      const progress = calculateScrollProgress(containerEl)
      const milestone = calculateMilestone(progress, current)

      if (milestone !== current) {
        const delta = progress - current
        const direction: ScrollDirection =
          axis === 'vertical' ? (delta > 0 ? 'down' : 'up') : delta > 0 ? 'right' : 'left'

        previousMilestone.current = milestone
        callback({ direction, milestone, progress })
      }
    }

    containerEl.addEventListener('scroll', handleScroll)
    return () => {
      containerEl.removeEventListener('scroll', handleScroll)
    }
  }, [axis, callback, milestones])

  return containerRef
}
