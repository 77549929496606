module.exports = {
  'tri-service': {
    DEFAULT: '#FF8F1C',
    tint: '#FFEEDD',
  },
  army: {
    DEFAULT: '#F9BF2A',
    tint: '#FEF5DF',
  },
  'air-force': {
    DEFAULT: '#549FD6',
    tint: '#E5F1F9',
  },
  navy: {
    DEFAULT: '#1877CF',
    tint: '#DCEBF8',
  },
  error: {
    DEFAULT: '#cb4c35',
    tint: '#FDE5E0',
  },
  success: {
    DEFAULT: '#67C13C',
    tint: '#E8F6E2',
  },
  black: {
    DEFAULT: '#000000',
    off: '#181818',
    'off-secondary': '#272727',
  },
  grey: {
    DEFAULT: '#959595',
    light: '#D8D8D8',
    pale: '#efefef',
    dark: '#333333',
    medium: '#595959',
  },
  white: {
    DEFAULT: '#FFFFFF',
    off: '#F8F8F8',
  },
  grid: {
    DEFAULT: 'rgba(255, 0, 0, 0.1)',
  },
  gradient: {
    DEFAULT: 'rgba(255, 255, 255, 0)',
  },
  transparent: 'transparent',
  inherit: 'inherit',
  current: 'currentColor',
}
