import { MouseEvent } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

export const useSitecoreClick = (callback: (e: MouseEvent) => void) => {
  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext?.pageState === 'edit'

  const handleMouseClick = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    callback(e)
  }

  const eventProps = !isEdit ? { onClick: handleMouseClick } : { onMouseUp: handleMouseClick }

  return eventProps
}
