/** @type {import('tailwindcss').Config} */
const settings = require('./tailwind.settings')
const colors = require('./tailwind.colors')

const remToPx = (rem) => {
  return `${rem * 16}px`
}
const clamp = (min = 0, max = 0) => {
  return `clamp(${min}rem, calc(${min}rem + (${max} - ${min}) * ((100vw - ${settings.screensRem.xs}rem) / (${settings.screensRem.xxl} - ${settings.screensRem.xs}))), ${max}rem)`
}

module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}', './safelist.txt'],
  darkMode: 'class',
  theme: {
    fontFamily: {
      inter: ['inter'],
      'uniform-condensed': ['uniform-condensed'],
      'uniform-condensed-light': ['uniform-condensed-light'],
    },
    screens: {
      xs: remToPx(settings.screensRem.xs),
      sm: remToPx(settings.screensRem.sm),
      md: remToPx(settings.screensRem.md),
      xm: remToPx(settings.screensRem.xm),
      lg: remToPx(settings.screensRem.lg),
      xl: remToPx(settings.screensRem.xl),
      xlg: remToPx(settings.screensRem.xlg),
      xxl: remToPx(settings.screensRem.xxl),
    },
    fontSize: {
      button: [
        '0.875rem',
        {
          lineHeight: '100%',
          letterSpacing: '1.5px',
        },
      ],
      xstag: [
        '0.75rem',
        {
          lineHeight: '120%',
          letterSpacing: '0.06em',
          fontWeight: 500,
        },
      ],
      stag: [
        '0.875rem',
        {
          lineHeight: '100%',
          letterSpacing: '0.06em',
        },
      ],
      mtag: [
        '0.875rem',
        {
          lineHeight: '120%',
          letterSpacing: '0.06em',
        },
      ],
      ltag: [
        '1.25rem',
        {
          lineHeight: '100%',
          letterSpacing: '0.01em',
        },
      ],
      ltagclamp: [
        clamp(0.875, 1.25),
        {
          lineHeight: '100%',
          letterSpacing: '0.01em',
        },
      ],
      snav: [
        '0.875rem',
        {
          lineHeight: '121.14%',
        },
      ],
      mnav: [
        '0.875rem',
        {
          lineHeight: '100%',
          letterSpacing: '0.5px',
        },
      ],
      xsbody: [
        '0.6875rem',
        {
          lineHeight: '145.46%',
        },
      ],
      sbody: [
        '0.875rem',
        {
          lineHeight: '142.86%',
        },
      ],
      mbody: [
        '1rem',
        {
          lineHeight: '150%',
        },
      ],
      lbody: [
        clamp(1.125, 1.375),
        {
          lineHeight: '163.64%',
        },
      ],
      meta: [
        clamp(1.125, 1.5),
        {
          lineHeight: '100%',
        },
      ],
      md: [
        clamp(1.25, 2),
        {
          lineHeight: '131.25%',
          letterSpacing: '-0.01em',
        },
      ],
      lg: [
        clamp(1.5, 2.5),
        {
          lineHeight: '120%',
          letterSpacing: '-0.01em',
        },
      ],
      xl: [
        clamp(1.75, 3),
        {
          lineHeight: '110.71%',
          letterSpacing: '-0.01em',
        },
      ],
      quote: [
        clamp(2, 3.75),
        {
          lineHeight: '110%',
          letterSpacing: '-0.01em',
        },
      ],
      squote: [
        clamp(1.75, 3),
        {
          lineHeight: '120%',
          letterSpacing: '-0.01em',
        },
      ],
      h6: [
        clamp(1.25, 1.5),
        {
          lineHeight: '133.33%',
          fontWeight: '600',
          letterSpacing: '-0.01em',
        },
      ],
      h5: [
        clamp(1.5, 2),
        {
          lineHeight: '125%',
          fontWeight: '600',
          letterSpacing: '-0.01em',
        },
      ],
      h4: [
        clamp(1.75, 3),
        {
          lineHeight: '110.42%',
          fontWeight: '500',
          letterSpacing: '-0.01em',
        },
      ],
      h3: [
        clamp(2, 3.75),
        {
          lineHeight: '90%',
          fontWeight: '600',
          letterSpacing: '-0.01em',
        },
      ],
      h2: [
        clamp(2.5, 4.37),
        {
          lineHeight: '90%',
          fontWeight: '600',
          letterSpacing: '-0.01em',
        },
      ],
      h1: [
        clamp(3, 5),
        {
          lineHeight: '90%',
          fontWeight: '600',
          letterSpacing: '-0.01em',
        },
      ],
      inherit: 'inherit',
      unset: 'unset'
    },
    colors,
    extend: {
      screens: {
        onlyhover: { raw: '(hover: hover)' },
      },
      outlineWidth: {
        3: '3px'
      },
      transitionProperty: {
        size: 'height, width',
        spacing: 'margin, padding',
        'size-spacing-opacity': 'height, width, opacity, margin, padding',
      },
      keyframes: {
        revealIn: {
          '0%': { transform: 'translateX(-20px)', opacity: 0 },
          '100%': { transform: 'translateX(0)', opacity: 1 },
        },
        revealOut: {
          '0%': { transform: 'translateX(0)', opacity: 1 },
          '100%': { transform: 'translateX(-20px)', opacity: 0 },
        },
        revealInRight: {
          '0%': { transform: 'translateX(20px)', opacity: 0 },
          '100%': { transform: 'translateX(0)', opacity: 1 },
        },
        revealInTop: {
          '0%': { transform: 'translateY(-20px)', opacity: 0 },
          '100%': { transform: 'translateY(0)', opacity: 1 }, // You can adjust the translateY value as needed
        },
        revealOutTop: {
          '0%': { transform: 'translateY(0)', opacity: 1 },
          '100%': { transform: 'translateY(-20px)', opacity: 0 },
        },
        fadeIn: {
          '0%': { opacity: 0, visibility: 'hidden' },
          '100%': { opacity: 1, visibility: 'visible' },
        },
        fadeOut: {
          '0%': { opacity: 1, visibility: 'visible' },
          '100%': { opacity: 0, visibility: 'hidden' },
        },
        fadeOut: {
          '100%': { opacity: 0, visibility: 'hidden' },
          '0%': { opacity: 1, visibility: 'visible' },
        }
     },
      animation: {
        'reveal-in-top': 'revealInTop 800ms ease-out forwards',
        'reveal-out-top': 'revealOutTop 800ms ease-out forwards',
        'reveal-in': 'revealIn 200ms ease-out forwards',
        'reveal-out': 'revealOut 200ms ease-out forwards',
        'reveal-in-right': 'revealInRight 200ms ease-out forwards',
        'fade-in': 'fadeIn 200ms ease-out forwards',
        'fade-out': 'fadeOut 200ms ease-out forwards'
      },
      backgroundImage: {
        'loading-icon':
            'url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22120%22%20height%3D%22120%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%3E%20%20%3Cstyle%3E%20%20%20%40keyframes%20loading-1%20%7B%20%20%20%20%20from%20%7B%20%20%20%20%20%20%20opacity%3A%200%3B%20%20%20%20%20%20%20transform%3A%20scale(0.3)%3B%20%20%20%20%20%7D%20%20%20%20%20to%20%7B%20%20%20%20%20%20%20opacity%3A%201%3B%20%20%20%20%20%20%20transform%3A%20scale(1)%3B%20%20%20%20%20%7D%20%20%20%7D%20%20%20%40keyframes%20loading-2%20%7B%20%20%20%20%20from%20%7B%20%20%20%20%20%20%20transform%3A%20translateX(0px)%3B%20%20%20%20%20%7D%20%20%20%20%20to%20%7B%20%20%20%20%20%20%20transform%3A%20translateX(7px)%3B%20%20%20%20%20%7D%20%20%20%7D%20%20%20%40keyframes%20loading-3%20%7B%20%20%20%20%20from%20%7B%20%20%20%20%20%20%20opacity%3A%201%3B%20%20%20%20%20%20%20transform%3A%20scale(1)%3B%20%20%20%20%20%7D%20%20%20%20%20to%20%7B%20%20%20%20%20%20%20opacity%3A%200%3B%20%20%20%20%20%20%20transform%3A%20scale(0.3)%3B%20%20%20%20%20%7D%20%20%20%7D%20%20%20polygon%20%7B%20%20%20%20%20fill%3A%20rgb(216%20216%20216)%3B%20%20%20%7D%20%20%20polygon%3Anth-child(2)%20%7B%20%20%20%20%20animation%3A%20loading-1%201000ms%20infinite%3B%20%20%20%20%20transform-origin%3A%200%2050%25%3B%20%20%20%7D%20%20%20polygon%3Anth-child(3)%20%7B%20%20%20%20%20animation%3A%20loading-2%201000ms%20infinite%3B%20%20%20%7D%20%20%20polygon%3Anth-child(4)%20%7B%20%20%20%20%20animation%3A%20loading-3%201000ms%20infinite%3B%20%20%20%20%20transform-origin%3A%20100%25%2050%25%3B%20%20%20%7D%20%20%3C%2Fstyle%3E%20%20%3Cpolygon%20points%3D%229.9%2C12%204%2C20%207.1%2C20%2013%2C12%207.1%2C4%204%2C4%22%20%2F%3E%20%20%3Cpolygon%20points%3D%229.9%2C12%204%2C20%207.1%2C20%2013%2C12%207.1%2C4%204%2C4%22%20%2F%3E%20%20%3Cpolygon%20points%3D%2216.9%2C12%2011%2C20%2014.1%2C20%2020%2C12%2014.1%2C4%2011%2C4%22%20%2F%3E%3C%2Fsvg%3E")'
      },
      spacing: {
        '0.5px': '0.5px',
        '0.75': '3px',
        '4.5': '1.125rem',
        18: '4.5rem',
        22: '5.5rem',
        26: '6.5rem',
      },
      borderWidth: {
        '0.5px': '0.5px',
      }
    },
  },
  safelist: [
    // whitelist icon highlight classes
    '[&_.highlight]:stroke-army',
    '[&_.highlight]:stroke-navy',
    '[&_.highlight]:stroke-air-force',
    '[&_.highlight]:stroke-tri-service',
    'divider-y'
  ],
  plugins: [require('@tailwindcss/container-queries'),],
}
