import { A11y, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/scss/pagination'

interface ImageSwiperProps {
  children: JSX.Element[] | undefined
  full?: boolean
}

const ImageSwiper = ({ children, full = false }: ImageSwiperProps) => {
  const swiperClasses = `
        w-full ${full ? 'h-full' : 'h-content'}
    `

  const paginationClasses = `
        [&_.swiper-pagination-bullet]:bg-transparent
        [&_.swiper-pagination-bullet]:opacity-100
        [&_.swiper-pagination-bullet]:border
        [&_.swiper-pagination-bullet]:w-4
        [&_.swiper-pagination-bullet]:h-4
        [&_.swiper-pagination-bullet]:!mx-1.5
        [&_.swiper-pagination-bullet]:border-white
        [&_.swiper-pagination-bullet-active]:!bg-white
        [&_.swiper-pagination]:mb-2.5
    `
  const gradientClasses = `
        z-[1] absolute left-0 bottom-0 w-full h-[43px] pointer-events-none
    `

  const gradientStyles = {
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.46) 42.97%, rgba(0, 0, 0, 0.70) 100%)',
    opacity: '0.7',
  }

  return (
    <Swiper
      className={`${swiperClasses} ${paginationClasses}`}
      modules={[Pagination, A11y]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={0}
    >
      {children?.map((x, i) => (
        <SwiperSlide key={i} className="w-full">
          {x}
        </SwiperSlide>
      ))}
      <div className={gradientClasses} slot="container-end" style={gradientStyles}></div>
    </Swiper>
  )
}

export default ImageSwiper
