import type { JSX } from 'react'

export type HeadingElementStyle = keyof JSX.IntrinsicElements | 'Sub heading'
type HeadingElement = HeadingElementStyle | Omit<string, HeadingElementStyle>

export function cleanHeadingStyle(style: HeadingElement): keyof JSX.IntrinsicElements
export function cleanHeadingStyle(style: undefined): undefined
export function cleanHeadingStyle(style: HeadingElement | undefined): keyof JSX.IntrinsicElements | undefined

export function cleanHeadingStyle(style: HeadingElement | undefined) {
  if (!style) {
    return undefined
  }
  return style === 'Sub heading' ? 'p' : (style.toLowerCase() as keyof JSX.IntrinsicElements)
}
