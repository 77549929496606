import { MouseEventHandler } from 'react'
import { useService } from 'src/utils/useService'

import Button, { ButtonProps } from '../Buttons/Button'
import { useShortlist } from './ShortlistProvider'

type ShortlistButtonProps = {
  id: string
  label?: {
    saved?: string | null
    unsaved?: string | null
  }
} & Pick<ButtonProps, 'service' | 'type' | 'className' | 'isSmall' | 'data-jobname' | 'data-jobtype'>

export default function ShortlistButton({ id, service: serviceOverride, ...props }: ShortlistButtonProps) {
  const service = useService(serviceOverride)
  const shortlist = useShortlist()

  const saved = shortlist.has(id)

  const buttonProps = shortlistButtonProps({
    id,
    saved,
    onClick: () => {
      const added = shortlist.toggle(id)
      shortlist.setShowShortlist(added)
    },
    service,
    ...props,
  })

  return <Button {...buttonProps} />
}

// Generate default props to define a shortlist button
export function shortlistButtonProps({
  id,
  saved,
  label = {},
  type = 'small-action',
  className = 'scroll-mt-80',
  ...rest
}: ShortlistButtonProps & { saved: boolean; onClick?: MouseEventHandler<HTMLButtonElement> }): ButtonProps {
  const text = saved ? label.saved || 'Saved' : label.unsaved || 'Shortlist'
  const icon = saved ? (id.startsWith('shortlist') ? 'minus' : 'check') : 'add'
  return {
    'data-trackingid': id,
    link: {
      value: {
        href: '#',
        text,
      },
    },
    icon,
    type,
    className,
    button: true,
    ...rest,
  }
}
