import React, { ReactNode, useEffect, useId, useLayoutEffect, useRef, useState } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'

import { FillIcons, IconNames, IconType } from 'components/front-end/Icons/icon-data'
import { useIntersect } from 'utils/intersection'
import { useService } from 'utils/useService'

interface UseDynamicSVGImportOptions {
  onCompleted?: (name: string, SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined) => void
}

export type ValidIconStyles = Pick<IconProps, 'name' | 'type'>

// Dynamic importer to load all SVGs as React Components on demand
function useDynamicSVGImport(
  name: ValidIconStyles['name'],
  type: ValidIconStyles['type'] = 'fill',
  isContent: boolean,
  options: UseDynamicSVGImportOptions = {}
) {
  const ImportedIconRef = useRef<React.FC<React.SVGProps<SVGSVGElement>>>()
  const [, setLoading] = useState(false)
  const { onCompleted } = options
  const fileName = isContent ? `content/${name}` : `${name}-${type}`

  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    setLoading(true)
    const importIcon = async (): Promise<void> => {
      try {
        const svgModule = await import(`public/icons/svgs/${fileName}.svg?icon`)
        ImportedIconRef.current = svgModule.ReactComponent
      } catch (err) {
        // Error don't output any svg to page
        const page = typeof window !== 'undefined' ? window.location.pathname : ''
        console.error('Icon load error', name, page, err)
        setLoading(false)
      } finally {
        onCompleted?.(name, ImportedIconRef.current)
        setLoading(false)
      }

      setLoading(false)
    }
    importIcon()
  }, [name, type, fileName, onCompleted])

  return { SvgIcon: ImportedIconRef.current }
}

export type IconProps<T extends IconNames = IconNames> = Omit<React.SVGProps<SVGSVGElement>, 'name'> & {
  name: T
  clicked?: boolean
  hover?: boolean
  height?: number | 'auto'
  width?: number | 'auto'
  reverse?: boolean
  service?: string
  onCompleted?: UseDynamicSVGImportOptions['onCompleted']
} & (T extends FillIcons ? { type?: IconType<T> } : { type: IconType<T> })

interface SVGElement extends Element {
  beginElement(): SVGElement
}

// The <Icon /> component
const Icon: React.FC<IconProps> = ({
  name,
  type,
  clicked = false,
  hover = false,
  reverse = false,
  onCompleted,
  ...rest
}): JSX.Element | null => {
  const uid = useId()
  const service = useService(rest.service)
  const [active, setActive] = useState(false)
  const [loaded, setIsLoaded] = useState(false)

  const contentIcon = type === 'content'
  const width = rest.width ?? (contentIcon ? 60 : 24)
  const height = rest.height ?? width
  const className = twMerge(
    'svg-icon',
    name,
    contentIcon ? `svg-content transition duration-500 [&_.highlight]:stroke-${service}` : 'svg-action',
    loaded ? 'loaded' : 'loading',
    reverse && 'reverse',
    rest.className
  )
  const { SvgIcon } = useDynamicSVGImport(name, type, contentIcon, { onCompleted })

  useEffect(() => {
    if (SvgIcon) setIsLoaded(true)
  }, [SvgIcon])

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setActive(!active)

    if (name === 'play') {
      const target = e.currentTarget
      const selector = `#uid${uid.replaceAll(':', '\\:')} ${
        target.classList.contains('clicked-active') ? '.animation-to-play' : '.animation-to-pause'
      }`
      const animation = target.querySelector<SVGElement>(selector)
      animation?.beginElement()
    }
  }

  if (!SvgIcon) {
    return null
  }

  const action = clicked || hover ? (clicked ? 'clicked' : 'hover') : undefined

  return (
    <IconWrapper id={uid} type={type} action={action} active={active} onClick={handleClick}>
      <SvgIcon {...rest} className={className} width={width} height={height} />
    </IconWrapper>
  )
}

type IconWrapperProps = {
  children: ReactNode
  onClick: React.MouseEventHandler<HTMLDivElement>
  action: 'clicked' | 'hover' | undefined
  active: boolean
  id: string
  type: IconProps['type']
}

const IconWrapper = ({ children, action, active, onClick, type, id }: IconWrapperProps) => {
  const iconNodeRef = useRef<HTMLDivElement | null>(null)
  const entry = useIntersect(iconNodeRef, '50px 0px -50px')

  return type === 'content' ? (
    <div
      ref={iconNodeRef}
      className={`self-start svg-${(entry?.intersectionRatio || 0) > 0.95 ? 'animated' : 'static'}`}
      data-entry={entry?.intersectionRatio}
    >
      {children}
    </div>
  ) : action ? (
    <div id={`uid${id}`} className={twJoin(`svg-${action}`, active && `${action}-active`)} onClick={onClick}>
      {children}
    </div>
  ) : (
    children
  )
}

export default Icon
