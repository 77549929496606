import React from 'react'

import StandardTag, { Tag } from 'components/front-end/Tags/StandardTag'

type StandardTagListProps = {
  tags: (string | Tag)[]
  className?: string
}

const StandardTagList = ({ tags, className }: StandardTagListProps) => {
  return (
    <div className={`tags flex gap-2 ${className}`}>
      {tags &&
        tags.map((tag, i) => (
          <StandardTag
            text={typeof tag === 'string' ? tag : tag.text}
            type={typeof tag === 'string' ? undefined : tag.type}
            key={i}
          />
        ))}
    </div>
  )
}

export default StandardTagList
