import { useEffect, useRef, useState } from 'react'
import { Field } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import Icon from '../Icons/Icon'
import { trackNavigation } from 'src/utils/tracking'

type MainNavSubItemProps = {
  title?: Field<string>
  path?: string
  type?: string
  onClick?: (bool: boolean) => void
  isSelected?: boolean
  isParent?: boolean
  isSmall?: boolean
  tabindex?: number
}

const MainNavMobile = ({
  title,
  path,
  onClick,
  isSelected,
  isParent = false,
  isSmall = false,
  tabindex = 0,
}: MainNavSubItemProps): JSX.Element => {
  // Icon wouldn't always load without a forced update
  const [icon, setIcon] = useState(false)

  useEffect(() => {
    // console.log('isParent', isParent)
    setIcon(isParent)
  }, [isParent])

  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const itemElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = itemElement?.current?.getBoundingClientRect().left || 0
    const width = itemElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < width / 2) finalX = 0
    if (finalX > width / 2) finalX = width

    setMousePosition({ left: finalX })
  }

  const itemClasses = `
        main-nav-item group/sub relative flex shrink-0 items-center
        has-[a:focus]:ring-[3px] has-[a:focus]:ring-offset-4 has-[a:focus]:ring-[--focus-color]
        text-grey w-full
    `
  const linkClasses = `
        main-nav-link group/link relative
        focus:ring-0
        ${isSelected ? 'is-active' : ''}
    `
  const titleClasses = `
        ${isSmall ? 'text-[26px] leading-[36px] font-uniform-condensed' : ''} w-full ml-auto
        transition duration-300 block
    `
  const lgTitleClasses = `
        py-[18px] text-10x h3
        [.light_&]:text-[inherit]
        group-hover/sub:text-black-off
        [.is-active_&]:text-black-off
    `
  const smTitleClasses = `
        py-3 text-[26px]
        group-hover/sub:text-black-off
        [.is-active_&]:text-black-off
    `
  const iconClasses = `
        flex justify-end items-center
        ml-auto mr-1 cursor-pointer h-10 w-10
        text-grey group-hover/sub:text-black-off
        ${icon ? 'flex' : 'hidden'}
    `
  const fillClasses = `
        main-nav-item-active z-0 absolute bottom-2 w-full ${isSmall ? 'h-[3px]' : 'h-1'}
        bg-black-off [.light_&]:bg-black-off [.dark_&]:bg-white [.grey_&]:bg-white
        scale-x-0 group-hover/link:scale-x-100 group-[.is-activex]/link:scale-x-100
        transition duration-400 delay-150 ease-linear
    `

  return (
    <div className={itemClasses}>
      <Link
        className={linkClasses}
        ref={itemElement}
        onClick={() => {
          onClick?.(false)
          trackNavigation({
            location: 'header',
            path: path?.replace(/^\//, '') as string,
          })
        }}
        onMouseMove={(ev) => handleMouseMove(ev)}
        href={path || '/'}
        tabIndex={tabindex}
      >
        <span className={`${isSmall ? smTitleClasses : lgTitleClasses} ${titleClasses}`}>{title?.value}</span>
        <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
      </Link>
      <button
        className={iconClasses}
        onClick={() => {
          onClick?.(true)
        }}
        title={`Open ${title?.value}`}
        tabIndex={tabindex}
      >
        <Icon name="chevron-right" type="stroke" width={40} height={40} />
      </button>
    </div>
  )
}

export default MainNavMobile
