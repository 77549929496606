import { useCallback, useEffect, useRef } from 'react'

const DEFAULT_DELAY = 100

export function throttle<T extends (...args: any[]) => void>(
  func: T,
  waitMilliseconds = DEFAULT_DELAY
): (...args: Parameters<T>) => void {
  let timeoutId: NodeJS.Timeout | null = null
  let lastExecutedTime = 0

  return function (this: any, ...args: Parameters<T>) {
    const context = this
    const currentTime = Date.now()

    if (currentTime - lastExecutedTime >= waitMilliseconds) {
      func.apply(context, args)
      lastExecutedTime = currentTime
    } else {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(
        () => {
          func.apply(context, args)
          lastExecutedTime = Date.now()
          timeoutId = null
        },
        waitMilliseconds - (currentTime - lastExecutedTime)
      )
    }
  }
}

export function useThrottle<T extends (...args: any[]) => void>(callback: T, delay = DEFAULT_DELAY): T {
  const callbackRef = useRef<T>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return useCallback(
    throttle((...args: Parameters<T>) => {
      callbackRef.current(...args)
    }, delay),
    [delay]
  ) as T
}
