import { useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { useRouter } from 'next/router'

import { track } from 'src/utils/tracking'
import SearchField from './SearchField'

interface SearchProps {
  searchActive: boolean
  onClose?: () => void
}

const SearchTakeover = ({ searchActive = false, onClose }: SearchProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext()
  const router = useRouter()

  const autocompleteSensitivity = 3

  const [query, setQuery] = useState<string>('')
  const [autocomplete, setAutocomplete] = useState<string[] | undefined>(undefined)

  // load postcodes
  useEffect(() => {
    if (!searchActive || query.length < autocompleteSensitivity) {
      setAutocomplete(undefined)
      return
    }

    const payload = {
      filterValues: [],
      page: 1,
      perPage: 6,
      query: query,
      searchId: '9e79bd2a-629f-462e-a236-afc87b575171',
    }
    if (!query || sitecoreContext?.pageEditing == true) return

    const ctrl = {
      isAborted: false,
    }

    //setIsLoading(true)
    fetch(`${process.env.DIRECT_HUB_API_HOST}/api/v2/search/KeywordSearch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((r) => r.json())
      .then((r) => {
        if (!ctrl.isAborted) {
          setAutocomplete(r?.Results)
        }
      })
    //.finally(() => /*setIsLoading(false))

    return () => {
      ctrl.isAborted = true
    }
  }, [searchActive, query, sitecoreContext?.pageEditing])

  const handleSearch = (q: string) => {
    router.push({
      pathname: '/search',
      query: {
        query: q,
        page: 1,
      },
    })
    track({
      event: 'search_completed',
      ga4category: `Header - Search`,
      searchinitiated_page: router.asPath,
      ga4name: q,
    })
    setQuery(q)
    onClose?.()
  }

  const takeoverClasses = `
    search-takeover adf-drawer
    fixed top-[--navbar-height] pointer-events-auto left-0 z-20
    bg-white border-t border-grey-light
    opacity-0 invisible overflow-hidden
    h-[calc(100vh_-_64px)] xl:h-[calc(100vh_-_88px)] w-full
    ${searchActive ? 'block animate-fade-in' : 'hidden animate-fade-out'}
  `

  const takeoverInnerClasses = `
    search-inner px-6 xm:px-0 navigation-grid py-8 pt-20 mx-auto
    h-full
  `

  return (
    <div className={takeoverClasses}>
      <div className={takeoverInnerClasses}>
        <div className="mx-auto max-w-[1160px]">
          <SearchField
            placeholderText={'Start typing'}
            searchTitle={'Website Search'}
            focusField={searchActive}
            routeQuery={''}
            updateResults={setQuery}
            searchClicked={handleSearch}
            queryList={{
              TotalCount: autocomplete?.length ?? 0,
              Results: autocomplete,
              Message: null,
            }}
            queryPath="/search"
            showTotal={true}
            onQueryClick={handleSearch}
            onClickTotal={handleSearch}
          />
          {
            <div className="pt-5">
              Searching for a role?{' '}
              <a href={`/careers`} className="link-underline mr-auto text-left text-grey-medium">
                Browse all careers
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default SearchTakeover
