import { useCallback, useEffect, useLayoutEffect } from 'react'

type LockType = {
  lockScroll: () => void
  unlockScroll: () => void
  lockedState: React.MutableRefObject<boolean>
}

// Makeshift singleton that acts like a mutable ref.
const lockedState = {
  current: false,
}

// @todo This executes on every render, which seems inefficient.
const useScrollLock = (): LockType => {
  const scrollBarCompensation = typeof window !== 'undefined' ? window.innerWidth - document.body.clientWidth : 0

  const lockScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      lockedState.current = true
      document.body.dataset.scrollLock = 'true'
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = 'var(--scrollbar-compensation)'
    }

    //   if (isiOS) {
    //     scrollOffset.current = window.pageYOffset;
    //     document.body.style.position = 'fixed';
    //     document.body.style.top = `-${scrollOffset.current}px`;
    //     document.body.style.width = '100%';
    //   }
  }, [])

  const unlockScroll = useCallback(() => {
    if (typeof window !== 'undefined') {
      lockedState.current = false
      document.body.style.overflow = ''
      document.body.style.paddingRight = ''

      //   if (isiOS) {
      //     document.body.style.position = '';
      //     document.body.style.top = ``;
      //     document.body.style.width = '';
      //     window.scrollTo(0, scrollOffset.current);
      //   }
      delete document.body.dataset.scrollLock
    }
  }, [])

  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`)
  }, [])
  return {
    lockScroll,
    unlockScroll,
    lockedState,
  }
}

export default useScrollLock
