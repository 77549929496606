import { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { ComponentRendering, Field, HtmlElementRendering, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { usePathname, useSearchParams } from 'next/navigation'
import Router from 'next/router'

import slugify from 'src/utils/slugifyStrings'
import { AudioGroupProps } from './Audio/_AudioInterface'
import { AncestorMap, findTabGroups, getTabDependencies } from './Tabs/tabDependencies'

type ReactText = string | number
type ReactChild = ReactText

type ReactNodeArray = Array<ReactNode>
type ReactFragment = object | ReactNodeArray
type ReactNode = ReactChild | ReactFragment | boolean | null | undefined

type Props = {
  children: React.ReactNode
}

let persistedReferer = ''

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LayoutContext = createContext<ReturnType<typeof usePageStateContext>>({} as any)

type TabState = {
  tabSet: Record<string, string>
  tabDependencies: AncestorMap
}

type BaseTabActions = { type: 'INIT'; payload: TabState } | { type: 'CLEAR' }

// What's exposed from the context
type TabAction = BaseTabActions | { type: 'SET'; payload: Record<string, string> }

// What's used by the reducer
type InternalTabAction = BaseTabActions | { type: 'SET'; payload: Record<string, string>; updateSearchParam: boolean }

function tabReducer(state: TabState, action: InternalTabAction) {
  switch (action.type) {
    case 'INIT': {
      return { ...action.payload }
    }
    case 'SET': {
      const payloadKeys = Object.keys(action.payload)
      let newParams = new URLSearchParams()
      const newTabSet = { ...state.tabSet, ...action.payload }
      payloadKeys.forEach((tabName) => {
        // @todo - set with '' aka delete does not clear child tabs (normal related tests apply)
        if (newTabSet[tabName] === '') {
          delete newTabSet[tabName]
        }
      })
      if (Object.keys(state.tabDependencies).length > 0) {
        newParams = new URLSearchParams(newTabSet)
        // Build the new url, but then check what we need to remove.
        Object.keys(state.tabSet)
          // Ignore ones set by this payload
          .filter((tabName) => !payloadKeys.includes(tabName))
          .forEach((tabName) => {
            // Best option is if we have a dependencies for this tab
            if (state.tabDependencies[tabName]) {
              // Determine if this existing tab is relative to the new state being set.
              const isARelativeTab =
                // Check if the tab is a parent of the new state
                state.tabDependencies[tabName].some((relative) => {
                  const parent = payloadKeys.some(
                    (newKey) => relative.tabName === newKey && relative.tabValue === newParams.get(tabName)
                  )
                  return parent
                }) ||
                // Check if the tab is a child of the new state
                payloadKeys.some((newKey) => {
                  const child = state.tabDependencies[newKey]?.some(
                    (relative) => relative.tabName === tabName && relative.tabValue === newParams.get(newKey)
                  )
                  return child
                })

              if (!isARelativeTab) {
                // Determine if this is any sort of relation to the new state being set.
                // This handles multiple tab sets on the page otherwise setting one will clear everything on the other.
                const isTabPartOfThisTabSet =
                  state.tabDependencies[tabName].some((relative) => {
                    const parent = payloadKeys.some((newKey) => relative.tabName === newKey)
                    return parent
                  }) ||
                  payloadKeys.some((newKey) => {
                    const child = state.tabDependencies[newKey]?.some((relative) => relative.tabName === tabName)
                    return child
                  })
                // Only purge tabs that aren't a relative, but is part of this tab set (cousins, not direct ascendents/descendants), ignore others.
                if (isTabPartOfThisTabSet) {
                  newParams.delete(tabName)
                }
              }
            }
          })
      } else {
        payloadKeys.forEach((tabName) => {
          if (
            ['tab', 'section-tab', 'title-tab', 'nav-tab'].includes(tabName) &&
            Object.keys(state.tabDependencies).length === 0
          ) {
            // Hardcoded clears for role pages (as they don't have sitecore context data and no hierarchy)
            newParams = new URLSearchParams(action.payload)
            // Only copy over ancestors if they exist
            // Parent tabs have no ancestors
            if (tabName === 'tab') {
              // No Parents
            } else if (tabName === 'section-tab') {
              if (newTabSet.tab) {
                newParams.set('tab', newTabSet.tab)
              }
            } else if (tabName === 'title-tab') {
              if (newTabSet.tab) {
                newParams.set('tab', newTabSet.tab)
              }
              if (newTabSet['section-tab']) {
                newParams.set('section-tab', newTabSet['section-tab'])
              }
            } else if (tabName === 'nav-tab') {
              if (newTabSet.tab) {
                newParams.set('tab', newTabSet.tab)
              }
              if (newTabSet['section-tab']) {
                newParams.set('section-tab', newTabSet['section-tab'])
              }
              if (newTabSet['title-tab']) {
                newParams.set('title-tab', newTabSet['title-tab'])
              }
            }
            // SideTabNavItems will have their own calculated name, they fall through maintaining the rest of the state.
            // When a parent changes, it will be cleared though.
          }
        })
      }

      if (action.updateSearchParam) {
        // Perform this in the next cycle, to avoid any setState in renders.
        setTimeout(() =>
          Router.replace(
            {
              pathname: Router?.asPath.split('?')[0],
              query: newParams.toString(),
            },
            undefined,
            { shallow: true }
          )
        )
      }
      return { ...state, tabSet: newTabSet }
    }
    case 'CLEAR': {
      return { tabSet: {}, tabDependencies: state.tabDependencies }
    }
  }
}

const getAbsoluteTop = (element: HTMLElement | null): number => {
  if (!element) {
    return 0
  }
  return element.offsetTop + getAbsoluteTop(element.offsetParent as HTMLElement)
}

// let run = false
export const usePageStateContext = () => {
  const [isStickyHeader, setIsStickyHeader] = useState(false)
  const [isVisibleFooter, setIsVisibleFooter] = useState(false)
  const [isVisibleBreadcrumbs, setIsVisibleBreadcrumbs] = useState(false)
  const [isOwnPath, setIsOwnPath] = useState(false)
  const [scrollDirection, setScrollDirection] = useState(false)
  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext?.pageEditing
  const globalService = (sitecoreContext?.route?.fields?.Service as Field<string>)?.value || 'tri-service'
  const [audioPlayList, setAudioPlayList] = useState<AudioGroupProps[]>([])
  const [audioPlayActive, setAudioPlayActive] = useState(0)
  const [audioIsPlaying, audioSetIsPlaying] = useState(false)
  const [isScriptOpen, setIsScriptOpen] = useState(false)

  // Workaround for jobs/events/other if a 404 inline occurs, set the page context to 404.
  const [isNotFound, setIsNotFound] = useState(false)

  const searchParams = useSearchParams()
  // Effectively a quick checksum of the previous searchParams to determine if search params is changed.
  const previousSearchParams = useRef(JSON.stringify(Object.fromEntries(searchParams)))

  const headlessMain = sitecoreContext.route?.placeholders['headless-main']
  // useSearchParams isn't necessarily immediate, so initially set the state if we have something in search params
  const [tabSet, setTabSet] = useReducer(tabReducer, null, () => {
    const tabGroups = findTabGroups(headlessMain)
    const tabDependencies = getTabDependencies(tabGroups)
    return {
      tabDependencies,
      tabSet: Object.fromEntries(searchParams),
    }
  })
  const pathname = usePathname()
  // Keep track of the previous pathname to determine if we need to reset the tab state
  const previousPathname = useRef('unset')
  const lastHeadlessMain = useRef<(ComponentRendering | HtmlElementRendering)[] | undefined>([])

  useEffect(() => {
    // Reset not found state
    if (pathname !== previousPathname.current && isNotFound) {
      setIsNotFound(false)
    }
    const searchParamsComparison = JSON.stringify(Object.fromEntries(searchParams))
    // If the pathname changes, or the search params have changed and are different to the current tab set (back button on browser, etc)
    if (
      lastHeadlessMain.current !== headlessMain ||
      pathname !== previousPathname.current ||
      (searchParamsComparison !== JSON.stringify(tabSet.tabSet) &&
        searchParamsComparison !== previousSearchParams.current)
    ) {
      const tabGroups = findTabGroups(headlessMain)
      const tabDependencies = getTabDependencies(tabGroups)
      // console.log('tabs config', { headlessMain, tabGroups, tabDependencies })
      // Check if there is name collision
      tabGroups.map((tab, index) => {
        // Need to verify against the slugified name
        const matchIndex = tabGroups.findLastIndex((matchTab) => slugify(tab.name) === slugify(matchTab.name))
        if (index !== matchIndex) {
          console.warn(
            'Duplicate Tab name detected, deep-linking will not work correctly.',
            tab,
            `Slug: ${slugify(tab.name)}`,
            tabGroups[matchIndex]
          )
        }
      })

      // Page change/load
      // Do we have a search param of a leaf node set?
      let matchedTab: string | undefined = undefined
      for (const [key, value] of searchParams) {
        // If the tab is a leaf node (has parent dependency, but child doesn't, or only a single child)
        // Or if there are no parent dependencies at all.
        if (
          (tabDependencies[key] && (!tabDependencies[value] || tabDependencies[value]?.length <= 1)) ||
          !tabDependencies[key]
        ) {
          // Try scanning the tabGroups for a matching tab and go with option index.
          // This handles tab sets that don't have nested tabs, or in some cases where there is a single (unrendered) tab with nested content
          const matchingTab = tabGroups.find((tabGroup) => slugify(tabGroup.name) === key)
          const index = matchingTab ? matchingTab.options.findIndex((option) => slugify(option) === value) : -1
          if (index > -1) {
            matchedTab = slugify(`${key}=${index}`)
            break
          } else {
            // Give up and just scroll to the start of the content
            matchedTab = slugify(`${key}=${value}`)
          }
        }
      }
      if (matchedTab) {
        // scroll if required
        // Pause to let things settle a little
        setTimeout(() => {
          const element = document.getElementById(matchedTab as string)
          if (!element) {
            console.warn("Couldn't find element to scroll to", { element, matchedTab })
            return
          }

          // Is there a breadcrumb?
          const breadcrumb = document.getElementsByClassName('adf-breadcrumb')?.[0] as HTMLDivElement
          // standard height + 10 buffer
          const offset = getAbsoluteTop(element) - (matchedTab === 'content' ? 0 : breadcrumb ? 64 + 10 : 0)

          window.scrollTo({ top: offset, behavior: 'smooth' })
        }, 500)
      }

      setTabSet({
        type: 'INIT',
        payload: { tabSet: Object.fromEntries(searchParams), tabDependencies },
      })
      lastHeadlessMain.current = headlessMain
    }
    previousPathname.current = pathname
    previousSearchParams.current = searchParamsComparison
  }, [pathname, searchParams, tabSet, isNotFound, headlessMain])

  function checkIsOwnPath() {
    if (window.location.hostname === persistedReferer) {
      setIsOwnPath(true)
    } else {
      persistedReferer = persistedReferer === '' ? 'referer' : window.location.hostname
    }
  }

  return useMemo(() => {
    return {
      pageContext: {
        deepLink: {
          tabSet: tabSet.tabSet,
          setTabSet: (action: TabAction) => {
            // updateSearchParam should be disabled for page editing mode.
            if (action.type === 'SET') {
              setTabSet({ ...action, updateSearchParam: sitecoreContext.pageState === 'normal' })
              return
            }
            setTabSet(action)
          },
        },
        audio: {
          audioPlayList,
          setAudioPlayList,
          audioPlayActive,
          setAudioPlayActive,
          audioIsPlaying,
          audioSetIsPlaying,
          isScriptOpen,
          setIsScriptOpen,
        },
        pageScroll: {
          scrollDirection,
          setScrollDirection,
        },
        history: {
          isOwnPath,
          checkIsOwnPath,
        },
        header: {
          isStickyHeader,
          setIsStickyHeader,
        },
        breadcrumbs: {
          isVisibleBreadcrumbs,
          setIsVisibleBreadcrumbs,
        },
        footer: {
          isVisibleFooter,
          setIsVisibleFooter,
        },
        isEdit,
        errorState: {
          isNotFound,
          setIsNotFound,
        },
        globalService,
      },
    }
  }, [
    audioIsPlaying,
    audioPlayActive,
    audioPlayList,
    globalService,
    isEdit,
    isNotFound,
    isOwnPath,
    isScriptOpen,
    isStickyHeader,
    isVisibleBreadcrumbs,
    isVisibleFooter,
    scrollDirection,
    sitecoreContext.pageState,
    tabSet.tabSet,
  ])
}

export function PageStateProvider({ children }: Props) {
  const contextValue = usePageStateContext()
  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>
}

export default function usePageContext() {
  return useContext(LayoutContext)
}
