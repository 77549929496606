import { CSSProperties } from 'react'
import { twMerge } from 'tailwind-merge'

import { Service, serviceColors, useService } from 'src/utils/useService'

type ServiceTagProps = {
  service?: Service | Omit<string, Service>
  isCard?: boolean
  isReserve?: boolean
  className?: string
}

const serviceDisplayName: Record<Service, string> = {
  army: 'Army',
  navy: 'Navy',
  'air-force': 'Air Force',
  'tri-service': 'All Services',
}

const ServiceTag = ({
  service: serviceOverride = 'tri-service',
  isCard,
  isReserve,
  className,
}: ServiceTagProps): JSX.Element => {
  const service = useService(serviceOverride)
  const serviceLabel = `${serviceDisplayName[service]}${isReserve && service !== 'tri-service' ? ' Reserve' : ''}`

  return (
    <div
      className={twMerge(
        'service-tag-container flex cursor-default items-center gap-x-1.5 whitespace-nowrap pb-1 pr-1.5 pt-[5px] text-black before:block before:size-2.5 before:shrink-0 before:bg-[--serviceColor] [.dark_&]:!text-white [.dark_.light_&]:!text-black [.light_&]:text-black',
        isCard ? 'xstag ' : 'ltagclamp before:pt-px sm:gap-x-2 sm:py-1.5 sm:pr-1.5',
        className
      )}
      style={{ '--serviceColor': serviceColors[service] } as CSSProperties}
    >
      <span>{serviceLabel}</span>
    </div>
  )
}

export default ServiceTag
