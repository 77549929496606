import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'

import colors from '../../tailwind.colors'

export const services = ['navy', 'army', 'air-force', 'tri-service'] as const
export type Service = (typeof services)[number]
export type ServiceString = Service | Omit<string, Service>

const fallbackService = 'tri-service'

// Overload signatures
export function useService<TService = ServiceString>(overrideService?: TService, fallback?: true): Service
export function useService<TService = ServiceString>(
  overrideService: TService | undefined,
  fallback: false
): Service | undefined

export function useService<TService = ServiceString>(
  overrideService?: TService | undefined,
  fallback = true
): Service | undefined {
  const { sitecoreContext } = useSitecoreContext()

  if (overrideService) {
    if (typeof overrideService !== 'string' || (overrideService as string) === 'triservice') {
      return 'tri-service' as Service
    }
    // Clean up any odd service names (apis can return them with capital letters, spaces).
    return overrideService?.toLowerCase().replaceAll(' ', '-') as Service
  }

  const pageService = (sitecoreContext?.route?.fields?.Service as Field<string>)?.value as Service | undefined
  if (pageService) {
    return pageService
  }
  if (fallback) {
    return fallbackService
  }
  return undefined
}

export const serviceColors = {
  navy: colors.navy.DEFAULT,
  army: colors.army.DEFAULT,
  'air-force': colors['air-force'].DEFAULT,
  'tri-service': colors['tri-service'].DEFAULT,
} as const
