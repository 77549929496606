import React, { useEffect } from 'react'
import { ComponentProps } from 'lib/component-props'
import Head from 'next/head'

import ADFErrorMessage, { ErrorProps } from 'components/ADFErrorMessage'
import ADFNavigation from 'components/ADFNavigation'
import { NavigationProps } from 'components/front-end/Navigation/_interface'

type NotFoundProps = {
  heading?: string | '404: NotFound'
  text?: string
  image?: {
    src: string
    alt: string
    width: number
    height: number
  }
}

const NotFound = (props: NotFoundProps): JSX.Element => {
  const [data, setData] = React.useState<ErrorProps | null>(null)
  const [nav, setNav] = React.useState<NavigationProps | null>(null)

  const backgroundImage = data?.fields?.ErrorImage?.value?.src || '/images/404.jpg'
  useEffect(() => {
    fetch(`/api/notfound`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((r) => r.json())
      .then((r) => {
        setNav(r.sitecore.route.placeholders['headless-header'][0])
        setData(r.sitecore.route.placeholders['headless-main'][0])
      })
      .catch((e) => console.log(`error`, e))
  }, [])

  return (
    <>
      <Head>
        <title>{props.heading}</title>
      </Head>
      {/* bg-gradient-to-b from-black/80 to-black/70 */}
      <div
        className={`grey flex min-h-screen w-screen flex-col  bg-gradient-to-b from-black/80 to-black/70  text-white`}
      >
        {nav && (
          <>
            <style jsx global>{`
              body {
                background-image: url(${backgroundImage});
                background-size: cover;
                background-position: center;
              }
              .header-gap {
                display: none;
              }
              .intersection-observer {
                display: none;
              }
            `}</style>

            <ADFNavigation params={nav.params} fields={nav.fields} />
            {data && (
              <ADFErrorMessage
                rendering={{ uid: 'not-found' } as ComponentProps['rendering']}
                params={data.params}
                fields={data.fields}
              />
            )}
          </>
        )}
      </div>
    </>
  )
}

export default NotFound
