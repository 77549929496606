import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'

import Drawer from 'components/front-end/Drawers/Drawer'
import JobShortlist from 'components/front-end/Drawers/drawer-components/Shortlist'
import Icon from 'components/front-end/Icons/Icon'
import { stringArrayParser, useLocalStorage } from 'utils/useLocalStorage'
import { Job as JobData } from '../JobDetails/_JobInterface'

// Create a context for the shortlisted IDs
const ShortlistContext = createContext<ShortlistContextValue | undefined>(undefined)

export const SHORTLIST_MAX = 8

// Custom hook to access the shortlisted IDs and CRUD operations
export const useShortlist = (): ShortlistContextValue => {
  const context = useContext(ShortlistContext)
  if (!context) {
    throw new Error('useShortlist must be used within a ShortlistProvider')
  }
  return context
}

export interface Job extends Omit<JobData, 'entryMethods'> {
  entryMethods: EntryMethod[]
}

export interface JobsShortlistResponse {
  CompareJobsSharePage: string
  compareJobsPage: string
  hideCompareTool: boolean
  jobs: Job[]
  error?: string
}

type ShortlistConfig = Omit<JobsShortlistResponse, 'jobs'>

interface EntryMethod {
  id: string
  title: string
  description: string
  longDescription: string
  isClosed: boolean
  salary: Salary
  compareRequirements: CompareRequirements
}

interface Salary {
  startingPay: StartingPay
  trainedPay: TrainedPay
}

interface StartingPay {
  name: string
  title: string
  perks: string
  amount: string
  isDaily: boolean
}

interface TrainedPay {
  name: string
  title: string
  perks: string
  amount: string
  isDaily: boolean
}

interface CompareRequirements {
  jobDescription?: string
  allowances?: string
  initialMilitaryTraining?: string
  employmentTraining?: string
  progression?: string
  ageRange?: string
  educationExperience?: string
  fitnessRequirements?: string
  minimumServicePeriod?: string
  jobComparisonStartingSalaryHeader: string
}

// Define the shape of the shortlist context value
type ShortlistContextValue = {
  items: string[] | null | undefined
  cache: Job[] | undefined
  jobs: Job[] | undefined
  add: (id: string) => void
  remove: (id: string) => void
  has: (id: string) => boolean
  toggle: (id: string) => boolean
  clear: () => void
  isFull: boolean
  showShortlist: boolean
  setShowShortlist: React.Dispatch<React.SetStateAction<boolean>>
  config: ShortlistConfig
}

export async function fetchShortlistedJobs(jobs: string[]): Promise<JobsShortlistResponse | undefined> {
  try {
    const response = await fetch(`/api/v2/search/JobsShortlist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ jobs }),
    })

    return response.json()
  } catch (error) {
    console.error('Error fetching shortlist', error)
    return undefined
  }
}

const defaultConfig: ShortlistConfig = {
  CompareJobsSharePage: '/compare-view',
  compareJobsPage: '/compare',
  hideCompareTool: false,
}

export const ShortlistProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const storedItems = useLocalStorage('ADF-shortlist', stringArrayParser)

  const [showShortlist, setShowShortlist] = useState(false)
  const [items, setItems] = useState<ReturnType<typeof stringArrayParser>>()
  const [overflow, setOverflow] = useState<string | undefined>()
  const [cache, setCache] = useState<Job[] | undefined>()
  const [config, setConfig] = useState<ShortlistConfig>(defaultConfig)

  const jobs = cache?.filter(({ id }) => Boolean(id && items && items.includes(id)))

  const { sitecoreContext } = useSitecoreContext()
  const isPageEditing = sitecoreContext?.pageState === 'edit'

  useEffect(() => {
    setItems((i) => {
      if (JSON.stringify(storedItems) !== JSON.stringify(i)) {
        return storedItems
      }
      return i
    })
  }, [storedItems])

  const updateCache = useCallback(async (jobs: string[]) => {
    const shortlistData = !isPageEditing && (await fetchShortlistedJobs(jobs))

    if (shortlistData) {
      const { jobs: jobData, ...shortlistConfig } = shortlistData

      setConfig(shortlistConfig)

      if (jobData) {
        setCache((data = []) =>
          shortlistData
            ? jobData.reduce((acc, job) => {
                if (job.id && !acc.find(({ id }) => id === job.id)) {
                  acc.push(job)
                }
                return acc
              }, data)
            : undefined
        )
      }
    }
  }, [])

  useEffect(() => {
    const newJobIDs = items?.filter((id) => !cache?.find((job) => job.id === id)) ?? []

    // Only fetch job data for new jobs that are not already cached in the shortlist
    // or first load to get settings
    if (!cache || newJobIDs.length) {
      updateCache(newJobIDs)
    }
  }, [cache, updateCache, items])

  // Load shortlisted IDs from localStorage on component mount
  useEffect(() => {
    const storedShortlist = localStorage.getItem('ADF-shortlist') || '[]'
    if (storedShortlist) {
      setItems(JSON.parse(storedShortlist))
    }
  }, [])

  // Save shortlisted IDs to localStorage whenever it changes
  useEffect(() => {
    if (items) {
      localStorage.setItem('ADF-shortlist', JSON.stringify(items))
    }
  }, [items])

  // Add an ID to the shortlist
  const add: ShortlistContextValue['add'] = (id) => {
    if (items?.length === SHORTLIST_MAX) {
      setOverflow(id)
    } else if (!items?.includes(id)) {
      setItems((prevItems = []) => (prevItems ? [...prevItems, id] : [id]))
    }
  }

  // Remove an ID from the shortlist
  const remove: ShortlistContextValue['remove'] = (id: string) => {
    if (overflow) {
      setOverflow(undefined)
    }
    setItems((prevItems = []) => prevItems?.filter((itemId) => itemId !== id) ?? prevItems)
  }

  // Check if an ID is in the shortlist
  const has: ShortlistContextValue['has'] = (id: string) => {
    return Boolean(items?.includes(id))
  }

  // Toggle an ID in the shortlist
  const toggle: ShortlistContextValue['toggle'] = (id: string) => {
    const hasId = has(id)
    if (hasId) {
      remove(id)
    } else {
      add(id)
    }
    return !hasId
  }

  // Clear the shortlist
  const clear: ShortlistContextValue['clear'] = () => {
    setItems([])
  }

  // Provide the shortlist and CRUD operations through the context
  return (
    <ShortlistContext.Provider
      value={{
        items,
        cache,
        jobs,
        add,
        remove,
        has,
        toggle,
        clear,
        isFull: Boolean(overflow),
        showShortlist,
        setShowShortlist,
        config,
      }}
    >
      {children}
      <Drawer
        title="My Shortlist"
        titleIcon="shortlist"
        show={showShortlist}
        closeDrawer={() => setShowShortlist(false)}
        hideFooter={config.hideCompareTool}
        footer={
          config.hideCompareTool ? undefined : (
            <div className="flex w-full">
              <Link
                href={config.compareJobsPage}
                className="icon-hover relative flex h-[60px] w-full select-none items-center justify-center gap-x-5 bg-tri-service aria-disabled:pointer-events-none aria-disabled:bg-grey-light aria-disabled:text-grey-medium"
                onClick={() => setShowShortlist(false)}
                aria-disabled={!items?.length}
              >
                <span className="button">Compare careers</span>
                <span className="svg-hover">
                  <Icon
                    name="chevron-right-double"
                    type="stroke"
                    className="before:absolute before:inset-0 before:bg-error"
                    width={15}
                    height={15}
                  />
                </span>
              </Link>
            </div>
          )
        }
      >
        <JobShortlist />
      </Drawer>
    </ShortlistContext.Provider>
  )
}
