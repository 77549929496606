import { sendGTMEvent } from '@next/third-parties/google'

import { FAQContentProps } from 'components/front-end/FAQ/FAQContent'
import { ScrollAxis } from './useScrollProgress'

/**
  Some pages may require additional metadata to be sent with the page view event.
  e.g. AnalyticsPageLoad<{ page_title: string; page_location: string; page_path: string }>
*/
export type AnalyticsPageLoad<T = { [index: string]: unknown }> = {
  ga4page: string
  page_height: number
} & T

type AnalyticsPageView = {
  page_view: string // <page name with hierarchy> - e.g. infantry-soldier/ways-to-join OR navy/our-story
}

export type FeedbackType = 'complaint' | 'technical' | 'other'
export type ContactMethod = 'phone' | 'email' | 'either'
type Services = 'navy' | 'army' | 'air-force' | 'tri-service' // This should be global
type AnalyticsFormSubmission = {
  event: 'form_submission'
} & (
  | { form_name: 'contact us'; contact_us_medium: ContactMethod }
  | { form_name: 'feedback'; feedback_type: FeedbackType }
  | {
      form_name: 'experience hub'
      experience_hub_content: string
      experience_hub_services: Services
    }
)

export type AttendeeType = 'Prospect' | 'Candidate' | 'Influencer'
export type EventAddGuest = `yes - ${number}` | `no`
type AnalyticsEventRegistration = {
  event: 'event_registration'
  event_name: string
  event_attendee_type: AttendeeType
  event_add_guest: EventAddGuest
}

export type JobType = 'priority to fill' | ''
type AnalyticsJobDetailsPageLoad = {
  event: 'page_load' // this is going to confuse someone
  job_name: string
  job_modelId: string
  job_type: JobType
}

type AudioActions = 'play' | 'pause'
type AnalyticsAudio = {
  event: 'audio_interaction'
  ga4name: `${string} - ${AudioActions}`
}

type AnalyticsButton = {
  event: 'button_click'
  ga4category: 'Button'
  ga4action: 'Button click'
  ga4name: `${string} - ${string}`
  click_location: string
}

type AnalyticsJobButton = {
  event: 'button_click'
  ga4category: 'Button'
  ga4action: 'Button click'
  ga4name: `${string} - ${string} - ${string}` | `${string} - ${JobType} - ${string} - ${string}`
  click_location: string
}

type AnalyticsMap = {
  event: 'map'
  map_click: `${string} - ${string}` // <title of card> - <link or action label>
}

type QuizAction =
  | 'Restart'
  | 'Back'
  | 'Next'
  | 'Learn more'
  | 'View your questionnaire progress'
  | 'See overview'
  | 'Save results'
export type AnalyticsQuiz = {
  event: 'quiz'
  quiz_name: string
  quiz_question: string
  quiz_answer: string
  quiz_button: QuizAction
}

export type FeedbackAction = 'select' | 'deselect'
type AnalyticsFeedback = {
  event: 'feedback'
  feedback_name: `${FeedbackAction} - ${string}` // <feedback action> - <name of feedback selection> - e.g. "select - Yes, very" OR "deselect - Could be better"
  feedback_component: string // <FAQ question> - e.g. "How fit do I need to be to join and serve?"
}

type AnalyticsDropdown = {
  event: 'expand'
  ga4category: 'Accordion'
  ga4action: 'Expand'
  ga4name: string
}

export type FilterAction = 'select' | 'deselect' | 'applied'
type AnalyticsFilter = {
  event: 'filter'
  filter_action: FilterAction
  filter_component: string
  /**
   *  We should standardise a format and delimit filter selections.
   *  e.g. "filter_selection": "Commitment:Full-Time;Service:Army,Navy - efc96bb1-53f2-42aa-b8eb-ca9ad7a1596a"
   */
  filter_selection: `${string} - ${string}` // <changed filter (select | deselect) OR aggregated filters (applied)> - <GUID>
}

type CarouselAction = 'Previous' | 'Next'
type CarouselDirection = 'Backward' | 'Forward'
type AnalyticsCarousel = (
  | {
      event: 'carousel_click'
      ga4action: `Click - ${CarouselAction}`
    }
  | {
      event: 'carousel_swipe'
      ga4action: `Swipe - ${CarouselDirection}`
    }
) & {
  ga4category: 'Carousel'
  ga4name: `${string} - ${string}` // <name of carousel component> - <page URL> - e.g. "hero_component - www.adfcareers.gov.au"
}

type AnalyticsSwiper = {
  event: 'story_swiper'
  swiper_action: `${SwipeDirection} - ${number} of ${number}` // <direction> - <current slide number> of <total slides>
  swiper_name: string
}

type AnalyticsTabButton = {
  event: 'tab_button'
  ga4category: 'tab_button'
  tab_component: string
  ga4name: `${string} - ${string}` // <name of tab button> - <GUID>
}

type SearchLocation = 'Header' | 'In-page'
type SearchAction = 'Click through Search' | 'Search'
type AnalyticsSearch = {
  event: 'search_completed'
  ga4category: `${SearchLocation} - ${SearchAction}` // <location of search OR GUID> - <search action> - e.g. Header - Click through Search OR In-page - Search
  searchinitiated_page: string // <url of page where search was conducted> - e.g. https://www.adfcareers.gov.au/jobs
  ga4name: string // <search term typed or clicked> e.g. "pilot" OR "Everything you need to know about pilots"
}

type NavLocation = 'header' | 'footer' | 'in_page_navigation'
type AnalyticsNavigation = {
  event: 'link_click'
  ga4category: 'Navigation Link'
  ga4action: 'Link click'
  ga4name: `${NavLocation} - ${string}` // <location of nav> - <path to nav link (level1>level2>level3)>
}

type AnalyticsScrollBar = {
  event: 'scroll_bar'
  scroll_bar_action: `${ScrollAxis} - ${number}%`
  scroll_bar_name: string
}

export type AnalyticsPayload =
  | AnalyticsAudio
  | AnalyticsButton
  | AnalyticsJobButton
  | AnalyticsCarousel
  | AnalyticsDropdown
  | AnalyticsEventRegistration
  | AnalyticsFeedback
  | AnalyticsFilter
  | AnalyticsFormSubmission
  | AnalyticsJobDetailsPageLoad
  | AnalyticsMap
  | AnalyticsNavigation
  | AnalyticsPageLoad
  | AnalyticsPageView
  | AnalyticsQuiz
  | AnalyticsSearch
  | AnalyticsSwiper
  | AnalyticsTabButton
  | AnalyticsScrollBar

type AnalyticsEventType = Extract<AnalyticsPayload, { event: string }>['event']
type AnalyticsPageEventType = 'pageload' | 'page_view'
type AnalyticsEventName = AnalyticsEventType | AnalyticsPageEventType
/**
 * The payload of the event to track.
 *
 * @template T - The type of the event. This can be either an `AnalyticsEventType` or an `AnalyticsPageEventType`.
 * @type {TrackingPayload<T>}
 * @example
 * type Payload = TrackingPayload<'swipe_action'>
 * // Payload is equivalent to:
 * type Payload = {
 *   event: 'swipe_action'
 *   swiper_action: `${SwipeDirection} - ${number} of ${number}`
 *   swiper_name: string
 * }
 */
type TrackingPayload<T extends AnalyticsEventName> = T extends AnalyticsEventType
  ? Extract<AnalyticsPayload, { event: T }>
  : T extends 'page_view'
    ? AnalyticsPageView
    : AnalyticsPageLoad

/**
 * Tracks an analytics event.
 *
 * @template T - The type of the event. This can be either an `AnalyticsEventType` or an `AnalyticsPageEventType`.
 * @param {TrackingPayload<T>} payload - The payload of the event to track.
 * @returns {void}
 *
 * @example
 * track({
 *   event: 'carousel_click',
 *   ga4category: 'Carousel',
 *   ga4action: 'Click - Next',
 *   ga4name: 'hero_component - www.adfcareers.gov.au'
 * })
 */
export function track<T extends AnalyticsEventName>(payload: TrackingPayload<T>) {
  if (process.env.NEXT_PUBLIC_VERBOSE_TRACKING) {
    console.table(payload) // TODO: Uncomment whilst testing tracking, delete when fully implemented
  }
  if ('dataLayer' in window) {
    sendGTMEvent(payload)
  }
}

// CONVENIENCE FUNCTIONS

export function trackPageView(path: string | string[]) {
  const payload: AnalyticsPageView = {
    page_view: Array.isArray(path) ? path.join('/') : path,
  }
  track(payload)
}

export function trackPageLoad() {
  if (typeof window === 'undefined') return

  const payload: AnalyticsPageLoad = {
    ga4page: window.location.pathname,
    page_height: window.document.body.offsetHeight,
  }

  track(payload)
}

export type SwipeDirection = 'left' | 'right' | 'up' | 'down'

type TrackSwiperArgs = {
  swiperName: string
  direction: SwipeDirection
  currentSlide: number
  totalSlides: number
}
export function trackSwiper({ swiperName, direction, currentSlide, totalSlides }: TrackSwiperArgs) {
  const payload: AnalyticsSwiper = {
    event: 'story_swiper',
    swiper_action: `${direction} - ${currentSlide} of ${totalSlides}`,
    swiper_name: swiperName,
  }
  track(payload)
}

export function trackAudio(title: string, action: AudioActions) {
  const payload: AnalyticsAudio = {
    event: 'audio_interaction',
    ga4name: `${title} - ${action}`,
  }
  track(payload)
}

type TrackButtonArgs = {
  title: string
  guid: string
  location?: string
}
const buttonClickArgs = {
  event: 'button_click',
  ga4category: 'Button',
  ga4action: 'Button click',
} as const
export function trackButton(args: TrackButtonArgs) {
  if ('jobName' in args) {
    return
  }
  const payload: AnalyticsButton = {
    ...buttonClickArgs,
    ga4name: `${args.title} - ${args.guid}` as const,
    click_location: args.location || '',
  }

  track(payload)
}

export type TrackJobButtonArgs = TrackButtonArgs & {
  jobName: string
  jobType?: JobType | ''
}
export function trackJobButton(args: TrackJobButtonArgs) {
  const payload: AnalyticsJobButton = {
    ...buttonClickArgs,
    ga4name: args.jobType
      ? (`${args.title} - ${args.jobType} - ${args.jobName} - ${args.guid}` as const)
      : (`${args.title} - ${args.jobName} - ${args.guid}` as const),
    click_location: args.location || '',
  }

  track(payload)
}

export function trackFAQ(faq: FAQContentProps) {
  trackButton({ title: faq.question, guid: faq.id, location: 'FAQ' })
}

export function trackNavigation({ location, path }: { location: NavLocation; path: string | string[] }) {
  const payload: AnalyticsNavigation = {
    event: 'link_click',
    ga4category: 'Navigation Link',
    ga4action: 'Link click',
    ga4name: `${location} - ${Array.isArray(path) ? path.join('>') : path}`,
  }
  track(payload)
}

export function trackDropdown(name: string) {
  const payload: AnalyticsDropdown = {
    event: 'expand',
    ga4category: 'Accordion',
    ga4action: 'Expand',
    ga4name: name,
  }
  track(payload)
}

export function trackScrollBar(name: string, percentage: number, axis: ScrollAxis) {
  const payload: AnalyticsScrollBar = {
    event: 'scroll_bar',
    scroll_bar_action: `${axis} - ${percentage}%`,
    scroll_bar_name: name,
  }
  track(payload)
}

export function trackEventRegistration(eventName: string, attendeeType: AttendeeType, addGuest: EventAddGuest) {
  const payload: AnalyticsEventRegistration = {
    event: 'event_registration',
    event_name: eventName,
    event_attendee_type: attendeeType,
    event_add_guest: addGuest,
  }
  track(payload)
}

export function trackJobDetailsPageLoad(jobName: string, jobModelId: string, jobType: JobType) {
  const payload: AnalyticsJobDetailsPageLoad = {
    event: 'page_load',
    job_name: jobName,
    job_modelId: jobModelId,
    job_type: jobType,
  }
  track(payload)
}

export function trackFilterInteraction(
  filterAction: FilterAction,
  filterComponent: string,
  filterSelection: `${string} - ${string}`
) {
  const payload: AnalyticsFilter = {
    event: 'filter',
    filter_action: filterAction,
    filter_component: filterComponent,
    filter_selection: filterSelection,
  }
  track(payload)
}

export function trackSearch(query: string, searchLocation: SearchLocation, page: string) {
  const payload: AnalyticsSearch = {
    event: 'search_completed',
    ga4category: `${searchLocation} - Search`,
    searchinitiated_page: page,
    ga4name: query,
  }
  track(payload)
}
