import { useEffect, useRef, useState } from 'react'

/**
 * @param divElm accepts a useRef<HTMLDivElement> to be passed into the observer
 * @var entry returns the element IntersectionObserverEntry objects, boundingClientRect, intersectionRatio, intersectionRect, isIntersecting, rootBounds, target, time
 * @returns {IntersectionObserverEntry:{intersectionRatio: 0}} the IntersectionObserverEntry objects
 */

export const useIntersect = (divElm: React.RefObject<HTMLDivElement>, rootMargin = '120px 0px -100px') => {
  const [entry, updateEntry] = useState<IntersectionObserverEntry>()
  const [node, setNode] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!node && divElm?.current) setNode(divElm?.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divElm?.current])

  const observer = useRef(
    typeof window !== 'undefined'
      ? new window.IntersectionObserver(([entry]: IntersectionObserverEntry[]) => updateEntry(entry), {
          root: null,
          rootMargin: rootMargin,
          threshold: Array.from(Array(100).keys(), (i) => i / 100),
        })
      : null
  )

  useEffect(() => {
    const { current: currentObserver } = observer
    if (node && currentObserver) currentObserver.observe(node)
    if (currentObserver) return () => currentObserver.disconnect()
    return
  }, [node])

  return entry
}
