import { InputHTMLAttributes, MouseEventHandler, useRef } from 'react'

import { useGreaterThan } from 'src/utils/useBreakpoints'
import Icon from '../Icons/Icon'

type ActionType = 'search' | 'filter' | 'location' | 'select'

type SearchInputProps = {
  resetClicked?: () => void
  showDegrees?: boolean
  isExpanded?: boolean
  label: string
  actions?: {
    name: ActionType | Omit<string, ActionType>
    icon?: JSX.Element
    label?: string
    onClick?: MouseEventHandler<HTMLButtonElement>
  }[]
  onChange: (value: string) => void
  value: string
  searchPos?: boolean
  onclickFilter?: () => void
  onLocationClick?: () => void
  inPage?: boolean
  filter?: number | undefined
  location?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>

const SearchInput = ({
  resetClicked,
  label,
  onChange,
  value = '',
  actions,
  searchPos,
  onclickFilter,
  onLocationClick,
  inPage,
  filter,
  location = false,
  showDegrees,
  isExpanded,
  ...props
}: SearchInputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  //const iconName = showDegrees ? 'chevron-down' : 'search'
  const searchIcon = <Icon name="search" type="stroke" height={34} width={34} className="[&_path]:!stroke-2" />
  actions ??= [
    {
      name: 'search',
      icon: searchIcon,
      onClick: () => {
        resetClicked?.()
        onChange('')
        ref.current?.focus()
      },
    },
  ]

  const barFilterLocationClasses = `
    bar w-[1px] h-[38px] border-r-[0.5px] border-grey ${searchPos ? 'mx-[20px] xm:mx-[24px]' : 'mx-[24px]'}
  `

  const counterClasses = `
    rounded-full text-black mtag h-[21px] w-[21px] items-center justify-center flex no-underline group-hover:bg-black group-hover:text-white transition duration-500
    [.tri-service_&]:bg-tri-service [.army_&]:bg-army [.air-force_&]:bg-air-force [.navy_&]:bg-navy
    ml-[3px] mr-[1px]
  `

  const labelFilterLocationClasses = `
    lbody font-normal flex overflow-hidden transition-size-spacing-opacity duration-200
    ${searchPos ? 'w-0 opacity-0 m-0 xm:w-auto xm:opacity-100 xm:ml-[6px]' : 'ml-[6px]'}
  `

  const searchFieldClasses = `
  search-field search-wrapper relative box-border w-full border-b border-grey-light pb-px
  before:block before:absolute before:inset-0 before:transition-[border] before:border-solid before-border-0 before:border-[--focus-color]
  group-has-[input:focus]:before:border-b-[3px]
  `

  return (
    <div className="group">
      <div className={searchFieldClasses}>
        <h1 className="search-title xstag mb-2 transition xm:mb-3">{label}</h1>
        <div className="search-inner relative mb-0.5 transition-all duration-300 ease-out md:mb-3">
          <div className="flex items-center">
            <input
              ref={ref}
              tabIndex={0}
              className="search-input h4 h-[53px] w-full flex-1 font-uniform-condensed font-medium text-black antialiased placeholder-grey-medium !outline-none focus:placeholder-grey focus-visible:ring-0 focus-visible:ring-offset-0"
              type="text"
              onChange={handleChange}
              value={value}
              {...props}
            />

            {actions.length ? (
              <div className="flex shrink-0 items-center divide-grey-light">
                {actions.map((action, i) => {
                  return (
                    <button
                      key={`${action.name}-${i}}`}
                      className={`icon-search svg-clicked flex size-5 shrink-0 items-center justify-center bg-white text-grey-medium transition-[size] md:size-6 xm:size-7 lg:size-[30px] xl:size-[53px] ${
                        !showDegrees && value.length ? 'clicked-active' : ''
                      } ${showDegrees && isExpanded ? 'clicked-active' : ''}`}
                      onClick={action.onClick}
                      aria-label={showDegrees ? 'Select Degree' : 'Search'}
                    >
                      {action.icon}
                      {action.label && <span className="mbody ml-1.5 font-normal">{action.label}</span>}
                    </button>
                  )
                })}
              </div>
            ) : null}

            {inPage && (useGreaterThan('xm') || searchPos) && (
              <>
                {filter != undefined && (
                  <>
                    <div className={barFilterLocationClasses}></div>
                    <button
                      className="svg-hover flex items-center justify-center bg-white"
                      onClick={() => {
                        if (onclickFilter) onclickFilter()
                      }}
                    >
                      <Icon name="filter" type="stroke" />
                      {searchPos && filter > 0 && <span className={counterClasses}>{filter}</span>}
                      <span className={labelFilterLocationClasses}>Filters</span>
                    </button>
                    {/* <FilterButton /> */}
                  </>
                )}
                {location && (
                  <>
                    <div className={barFilterLocationClasses}></div>
                    <button
                      className="svg-hover flex items-center justify-center bg-white"
                      onClick={() => {
                        if (onLocationClick) onLocationClick()
                      }}
                    >
                      <Icon name="location" type="stroke" />
                      <span className={labelFilterLocationClasses}>Location</span>
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchInput
