import React, { MouseEvent, ReactNode, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useGreaterThan } from 'src/utils/useBreakpoints'
import Icon from '../Icons/Icon'

interface SearchMatchDataProps<T = string> {
  TotalCount: number
  Results?: T[]
}

export type RenderItemProps<T> = {
  text: string
  richText: string
  item: T
  onClick?: (item: T) => void
}

export type RenderProps<T> = ({ text, richText, item, onClick }: RenderItemProps<T>) => React.ReactNode

interface SearchMatchProps<T = string> {
  query?: string
  minQueryLength?: number
  results?: SearchMatchDataProps<T> | null
  path?: string
  noResults?: ReactNode
  display?: (result: T) => string
  keyFn?: (item: T) => string
  onClick?: (result: T) => void
  onClickTotal?: (e: MouseEvent<HTMLAnchorElement>) => void
  showTotal?: boolean
  children?: RenderProps<T>
  as?: React.ElementType
}

export const boldQueryMatch = (match: string, query: string) => {
  const strIdx = match.toLocaleLowerCase().indexOf(query.toLocaleLowerCase())
  const strMatch = match.slice(strIdx, strIdx + query.length)
  return match.replaceAll(strMatch, `<strong>${strMatch}</strong>`)
}

const SearchInputResults = <T,>({
  query = '',
  minQueryLength = 1,
  results,
  path,
  noResults,
  display,
  keyFn,
  onClick,
  onClickTotal,
  showTotal,
  children,
  as: List = 'ul',
}: SearchMatchProps<T>) => {
  const items = results?.Results ?? []
  const isMediumDisplay = useGreaterThan('md')

  const searchListClasses = `search-list adf-scroll-bar-container
  top-full z-10 w-full border-grey-light bg-white md:border
  py-3 md:-translate-y-0.5 px-6 xm:px-0 mx-auto`

  const [isHover, setIsHover] = useState(false)
  const router = useRouter()
  const basePath = path
    ? path
    : router?.query?.path
      ? (router?.query?.path as string[]).map((x) => {
          return x.toLowerCase()
        })
      : []

  const MatchItem = <T,>({ text, richText, item, onClick: onClickItem }: RenderItemProps<T>) => {
    const handleClick = onClickItem ?? (onClick as typeof onClickItem) ?? undefined
    return (
      <Link
        href={`${basePath}?query=${encodeURIComponent(text)}`}
        onClick={(event) => {
          event?.preventDefault()
          handleClick?.(item)
        }}
        className="search-item block w-full py-3 text-grey-medium transition duration-200 hover:bg-white-off focus-visible:ring-inset focus-visible:ring-offset-0 md:pl-4 md:pr-10"
        dangerouslySetInnerHTML={{ __html: richText }}
      />
    )
  }

  const QueryWrapper = ({ children }: { children: ReactNode | undefined }) => {
    return List === 'ul' ? <li>{children}</li> : <>{children}</>
  }

  const render = children ?? ((props: RenderItemProps<T>) => <MatchItem {...props} />)

  if (query.length < minQueryLength || (!items.length && !noResults)) {
    return null
  }

  return (
    <>
      {items.length ? (
        <div className={searchListClasses}>
          <>
            <List className="search-match adf-scroll-bar -mx-6 max-h-[500px] overflow-y-scroll md:mx-0 md:max-h-[280px]">
              {items.map((item, i): ReactNode => {
                const text = display ? display(item) ?? '' : (item as string)
                const key = typeof keyFn === 'function' ? keyFn(item) : `${text}-${i}`
                return (
                  <QueryWrapper key={key}>
                    {render({ item, text, richText: boldQueryMatch(text, query), onClick } as RenderItemProps<T>)}
                  </QueryWrapper>
                )
              })}
            </List>
            {showTotal ? (
              <Link
                href={`${path}?query=${query}`}
                className={`icon-hover-${isHover} fixed bottom-0 left-0 z-10 mt-6 flex h-16 w-full items-center justify-center border-y border-grey-light bg-white py-2 text-grey-medium focus-visible:ring-inset focus-visible:ring-offset-0 md:relative md:bottom-auto md:left-auto md:h-auto md:justify-start md:border-0 md:px-4`}
                onMouseEnter={() => isMediumDisplay && setIsHover(true)}
                onMouseLeave={() => isMediumDisplay && setIsHover(false)}
                onClick={onClickTotal}
              >
                <span className="xstag-bold mr-2">See all ({results?.TotalCount}) results</span>
                <Icon name="chevron-right-double" type="stroke" hover height={12} width={12} />
              </Link>
            ) : null}
          </>
        </div>
      ) : (
        noResults
      )}
    </>
  )
}

export default SearchInputResults
