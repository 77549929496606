import React, { useEffect, useRef, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config.js'

// Not written in TS as it imports tailwind.config.js that has no TS definition

const fullConfig = resolveConfig(tailwindConfig)

function BreakPoint(elRef, breakpoints) {
  const firstQuery = Object.keys(breakpoints[0])[1]
  const [breakSize, setBreakSize] = useState(firstQuery)

  const observer = useRef(
    typeof window !== 'undefined'
      ? new ResizeObserver((entries) => {
          const { width } = entries[0].contentRect
          if (width) {
            setBreakSize(findBreakPoint(breakpoints, width))
          }
        })
      : null
  )

  useEffect(() => {
    if (elRef && observer) observer.current.observe(elRef)
    // return () => observer.current.unobserve()
  }, [elRef, observer])

  return breakSize
}

function findBreakPoint(breakpoints, width) {
  const breakpointIndex = breakpoints.map((x) => Object.values(x)[0]).findIndex((x) => width < x)
  if (breakpointIndex === -1) {
    return Object.values(breakpoints[breakpoints.length - 1])[0]
  }
  return breakpoints[breakpointIndex] ? Object.values(breakpoints[breakpointIndex])[0] : 375
}

const twToBp = []
for (const property in fullConfig.theme.screens) {
  twToBp.push({ [property]: parseInt(fullConfig.theme.screens[property]) })
}
// Removing the HoverOnly selector from tailwind screens data as its not a BP
twToBp.pop()
twToBp.splice(6, 0, { nav: 1700 }) // Added for custom NAV breakpoint

const useBreakPoint = () => {
  const responsiveRef = useRef(typeof window !== 'undefined' ? document.body : null)
  const size = BreakPoint(responsiveRef.current, twToBp)
  return size
}

// {xs: 375}
// {sm: 428}
// {md: 768}
// {xm: 1024}
// {lg: 1280}
// {xl: 1440}
// {nav: 1700} - Was added JS only Screen Media Query
// {xxl: 1920}
// {hoveronly: NAN} - Was removed as its not a BP but still a Screen Media Query in Tailwind config

const useGreaterThan = (target) => useBreakPoint() > twToBp.find((x) => Object.keys(x)[0] === target)[target]

const useLessThan = (target) => useBreakPoint() <= twToBp.find((x) => Object.keys(x)[0] === target)[target]

export { useBreakPoint, useGreaterThan, useLessThan }
