import { useRef, useState } from 'react'
import Link from 'next/link'

type InPageNavLinkProps = {
  linkText?: string
  isActive?: boolean
  linkHref?: string
  onClick?: () => void
  className?: string
}

const InPageNavLink = ({
  linkText,
  linkHref = '',
  isActive = false,
  className = '',
  onClick,
}: InPageNavLinkProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  })

  const tabElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = tabElement?.current?.getBoundingClientRect().left || 0
    const width = tabElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < 20) finalX = 0
    if (finalX > width - 20) finalX = width

    setMousePosition({ left: finalX, top: ev.pageY })
  }

  const textCommonClasses = `group text-grey-medium [.dark_&]:text-white [.grey_&]:text-white snav
    [.dark_&]:hover:border-white [.grey_&]:hover:border-white
    relative inline-block ${isActive ? 'is-active' : ''}
    overflow-hidden text-ellipsis whitespace-nowrap max-w-[400px]
  `
  const textActiveClasses = `
    is-active
    cursor-default decoration-none
  `
  const textLinkClasses = `
    hover:text-black cursor-pointer
  `
  const fillClasses = `
    tab-fill z-0 absolute bottom-0 left-0 h-px w-full
    bg-black-off [.dark_&]:bg-white [.grey_&]:bg-white
    scale-x-0 group-hover:scale-x-100 group-[.is-active]:scale-x-100
    transition duration-400 ease-linear
  `

  if (isActive) {
    return (
      <span className={`${textCommonClasses} ${textActiveClasses}`}>
        {linkText}
        <span className={fillClasses}></span>
      </span>
    )
  } else {
    return (
      <Link
        ref={tabElement}
        onMouseMove={(ev) => handleMouseMove(ev)}
        className={`${textCommonClasses} ${textLinkClasses}`}
        href={linkHref}
        onClick={onClick}
      >
        {linkText}
        <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
      </Link>
    )
  }
}

export default InPageNavLink
