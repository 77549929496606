import { useRef, useState } from 'react'
import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { trackNavigation } from 'src/utils/tracking'

type MainNavItemProps = {
  title?: Field<string>
  path?: string
  type?: string
  onMouseOver?: () => void
  onMouseOut?: () => void
  onClick?: () => void
  isExperience?: boolean
  isSelected?: boolean
}

const MainNavItem = ({
  title,
  path,
  onMouseOver,
  onMouseOut,
  onClick,
  isExperience,
  isSelected,
}: MainNavItemProps): JSX.Element => {
  const [mousePosition, setMousePosition] = useState({
    left: 0,
  })

  const itemElement = useRef<HTMLAnchorElement>(null)

  function handleMouseMove(ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const offsetLeft = itemElement?.current?.getBoundingClientRect().left || 0
    const width = itemElement?.current?.getBoundingClientRect().width || 0

    let finalX = ev.pageX - offsetLeft
    if (finalX < width / 2) finalX = 0
    if (finalX > width / 2) finalX = width

    setMousePosition({ left: finalX })
  }

  // {xs: 375}
  // {sm: 428}
  // {md: 768}
  // {xm: 1024}
  // {lg: 1280}
  // {xl: 1440}
  // {nav: 1700} JS only
  // {xxl: 1920}

  const itemClasses = `
        main-nav-item group/link relative h-full lg:px-3 [@media(min-width:1700px)]:px-[17px]
        focus-visible:ring-0 focus-visible:ring-offset-0
        before:block before:absolute before:inset-0 before:transition-[outline] before:outline before:outline-0 before:outline-[--focus-color]
        focus-visible:before:outline-3
        ${isSelected ? 'is-active' : ''}
    `

  const titleClasses = `
        mnav uppercase
        transition-colors duration-300
        text-grey-medium [.light_&]:text-black-off [.dark_&]:text-white [.grey_&]:text-white
        [.is-active_&]:text-black-off [.light_&]:[.is-active_&]:text-black-off [.dark_&]:[.is-active_&]:text-white [.grey_&]:[.is-active_&]:text-white
    `

  const fillClasses = `
        main-nav-item-active z-0 absolute bottom-0 w-full h-1
        bg-black-off [.light_&]:bg-black-off [.dark_&]:bg-white [.grey_&]:bg-white
        scale-x-0 group-hover/link:scale-x-100 group-[.is-active]/link:scale-x-100
        transition duration-[400] delay-150 ease-linear
    `
  return (
    <Link
      ref={itemElement}
      onMouseMove={(ev) => handleMouseMove(ev)}
      href={path || '/'}
      tabIndex={0}
      onMouseOver={() => onMouseOver?.()}
      onFocus={() => onMouseOver?.()}
      onBlur={() => onMouseOut?.()}
      onMouseOut={() => onMouseOut?.()}
      onClick={() => {
        onClick?.()
        trackNavigation({
          location: 'header',
          path: path?.replace(/^\//, '') as string,
        })
      }}
      className={itemClasses}
    >
      <span className="relative flex h-full w-full items-center">
        {isExperience ? (
          <Text className={`lbody block ${isSelected ? 'text-white' : 'text-grey-medium'}`} field={title} />
        ) : (
          <span className={titleClasses}>{title?.value}</span>
        )}
        <span className={fillClasses} style={{ transformOrigin: mousePosition.left }}></span>
      </span>
    </Link>
  )
}

export default MainNavItem
