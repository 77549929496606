import { MouseEvent, useEffect, useRef, useState } from 'react'
import { RichText as JssRichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs'
import { Service, useService } from 'src/utils/useService'
import { useSitecoreClick } from 'src/utils/useSitecoreClick'

import usePageContext from '../_layoutContext'
import Icon, { IconProps } from '../Icons/Icon'

const color = {
  'tri-service': 'bg-tri-service-tint',
  army: 'bg-army-tint',
  'air-force': 'bg-air-force-tint',
  navy: 'bg-navy-tint',
}

const toastIcons = {
  Alert: {
    name: 'error',
    type: 'fill',
    color: 'text-tri-service',
  },
  Success: {
    name: 'check-circle',
    type: 'fill',
    color: 'text-success',
  },
  Failed: {
    name: 'close-circle',
    type: 'fill',
    color: 'text-error',
  },
  Warning: {
    name: 'warning',
    type: 'fill',
    color: 'text-tri-service',
  },
} as const

type NotificationProps = {
  text: string
  id?: string
  icon?: Pick<IconProps, 'name' | 'type'>
  toastIconType?: keyof typeof toastIcons
  isToast?: boolean
  serviceName?: Service
  isGrey?: boolean
}

const Notification = ({
  text,
  icon,
  isGrey,
  id = '',
  isToast = false,
  toastIconType,
  serviceName,
}: NotificationProps): JSX.Element | null => {
  const state = usePageContext()
  const service = useService((serviceName ?? state?.pageContext?.globalService) as Service, false)

  const [fadeClass, setFadeClass] = useState('')
  const [showToast, setShowToast] = useState(true)

  const { sitecoreContext } = useSitecoreContext()
  const isEdit = sitecoreContext?.pageEditing

  const notificationRef = useRef<HTMLInputElement | null>(null)

  const onClickToast = (event: MouseEvent) => {
    event.preventDefault()
    setFadeClass('fade')
    window.sessionStorage.setItem(`toast-notification-${id}`, 'true')

    setTimeout(() => {
      if (notificationRef.current) notificationRef.current.classList.add('no-display')
    }, 500)
  }

  const toastCloseProps = useSitecoreClick(onClickToast)

  useEffect(() => {
    if (isToast && !isEdit && window.sessionStorage.getItem(`toast-notification-${id}`)) {
      setShowToast(false)
    }
  }, [id, isEdit, isToast])

  const containerClasses = isToast ? 'fixed right-0 toast-notification mb-3' : ''

  const backgroundColour = isGrey
    ? 'bg-grey-pale text-black'
    : service
      ? `${color[service as Service]} [.dark_&:not(.light_.bg-grey-dark)]:bg-grey-dark`
      : 'bg-white-off [.light_&]:bg-white-off [.dark_&:not(.light .bg-grey-dark)]:bg-grey-dark'

  const toastIcon = (isToast && toastIconType && toastIcons[toastIconType]) || icon
  const iconFillClass = toastIcon && 'color' in toastIcon ? toastIcon.color : ''

  if (isToast && !showToast) {
    return null
  } else {
    return (
      <div
        className={`${backgroundColour} adf-notification w-full py-4 xs:py-5 ${containerClasses} ${fadeClass}`}
        ref={notificationRef}
      >
        <div className="flex px-4 xs:px-8">
          {toastIcon ? (
            <div className="pr-3">
              <Icon
                name={toastIcon.name}
                type={toastIcon.type}
                className={`h-[22px] w-[22px] xs:h-6 xs:w-6 ${iconFillClass}`}
              />
            </div>
          ) : null}
          <div
            className={`sbody xs:mbody [&_.button-adf]:text-black [&_.button-adf]:underline [&_a]:underline [.dark_&]:[&_.button-adf]:text-white [.light_&]:text-black`}
          >
            {text ? <JssRichText field={{ value: text }} /> : ''}
          </div>
          {isToast && (
            <div className="ml-auto flex pl-3 xs:items-center">
              <button type="button" {...toastCloseProps} className="h-5 shrink-0">
                <Icon name="close" type="stroke" className="size-5 shrink-0" />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Notification
