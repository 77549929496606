import MainNavAction, { MainNavActionProps } from '../Navigation/MainNavAction'
import { useShortlist } from './ShortlistProvider'

export default function ShortlistNavAction(props: MainNavActionProps): ReturnType<typeof MainNavAction> {
  const { items = [], setShowShortlist } = useShortlist()
  return (
    <MainNavAction
      count={items?.length ?? 0}
      onClick={() => {
        setShowShortlist(true)
      }}
      {...props}
    />
  )
}
