import React, { useCallback, useEffect, useId, useState } from 'react'
import FocusTrap from 'focus-trap-react'

import Divider from './drawer-components/Divider'
import useScrollLock from 'src/utils/scrollLock'
import { trackScrollBar } from 'src/utils/tracking'
import { useGreaterThan } from 'src/utils/useBreakpoints'
import { useScrollProgress } from 'src/utils/useScrollProgress'
import Button, { ButtonProps } from '../Buttons/Button'
import Icon from '../Icons/Icon'

const iconTitles = {
  filter: 'stroke',
  location: 'fill',
  shortlist: 'stroke',
} as const

type DrawerProps = {
  show: boolean
  title: string
  titleIcon?: keyof typeof iconTitles
  id?: string
  closeDrawer: () => void
  children: React.ReactNode
  hideFooter?: boolean
  footer?: React.ReactNode
  primaryText?: string
  primaryIcon?: ButtonProps['icon']
  primaryClick?: () => void
  secondaryText?: string
  secondaryIcon?: ButtonProps['icon']
  secondaryClick?: () => void
  returnFocusOnClose?: boolean
}

const Drawer = (props: DrawerProps) => {
  const {
    show,
    closeDrawer,
    title,
    titleIcon,
    id,
    children,
    hideFooter,
    footer,
    primaryText,
    primaryIcon,
    secondaryText,
    secondaryIcon,
    returnFocusOnClose = true,
  } = props
  const transformClass = show ? 'translate-x-0' : 'translate-x-full'
  const iconType = titleIcon
  const fallbackId = useId()
  const titleId = `drawer-title-${id || fallbackId}`

  const { lockScroll, unlockScroll } = useScrollLock()
  const [fadeMask, setFadeMask] = useState(false)
  const scrollContainerRef = useScrollProgress(
    ({ milestone }) => {
      trackScrollBar('ADFReserveUnitLocator', milestone, 'vertical')
    },
    { axis: 'vertical', milestones: [0, 25, 50, 75, 100] }
  )

  useEffect(() => {
    if (show) {
      setFadeMask(true)
      lockScroll()
    }
  }, [lockScroll, show, setFadeMask])

  useEffect(() => {
    return () => {
      unlockScroll()
      setFadeMask(false)
    }
  }, [unlockScroll])

  const handleTransitionEnd = useCallback(() => {
    if (!show) {
      unlockScroll()
      setFadeMask(false)
    }
  }, [show, unlockScroll])

  return (
    <>
      <FocusTrap active={show} focusTrapOptions={{ returnFocusOnDeactivate: returnFocusOnClose }}>
        <div
          inert={!show ? '' : undefined}
          id={id}
          className={`${transformClass} adf-drawer adf-scroll-bar-container light fixed right-0 top-0 z-[200] flex h-full max-h-full w-full flex-col justify-start bg-white text-black transition-transform duration-500 md:w-[512px] xm:w-[540px] xl:w-[640px]`}
          onTransitionEnd={handleTransitionEnd}
          role="dialog"
          aria-labelledby={titleId}
          aria-hidden={!show}
        >
          <div className="flex max-h-full flex-1 flex-col" role="region" aria-labelledby={titleId}>
            <div className="flex flex-row justify-between pb-[24.5px] pl-6 pt-6 xs:pr-8 sm:pl-10">
              <div className="flex cursor-default flex-row items-center [.dark_&]:text-black">
                {iconType && (
                  <span className="pr-1.5">
                    <Icon type={iconTitles[titleIcon]} name={titleIcon} width={20} height={20} className="text-black" />
                  </span>
                )}
                <div className="lbody" id={titleId}>
                  {title}
                </div>
              </div>
              <button
                tabIndex={show ? 0 : -1}
                onClick={(e) => {
                  e.preventDefault()
                  closeDrawer()
                  unlockScroll()
                }}
                className="close flex items-center"
              >
                <span className="pr-1.5">
                  <Icon type="stroke" name="close" width={15} height={15} className="text-black" />
                </span>
                <div className="button">Close</div>
              </button>
            </div>
            <Divider widthPercent={94}></Divider>
            <div
              className={`block flex-1 overflow-y-auto pb-10 pl-6 pr-0 xs:pr-7 sm:mr-8 sm:pl-10 ${
                useGreaterThan('md') ? '' : 'adf-drawer-scroller-hidden'
              } ${hideFooter}`}
              ref={scrollContainerRef}
            >
              {children && React.Children.map(children, (c) => c)}
            </div>
            {!hideFooter && (
              <div className="relative z-10 flex w-full divide-x border-t before:pointer-events-none before:absolute before:-top-px before:left-0 before:right-[6%] before:z-[200] before:h-[50px] before:w-[94%] before:-translate-y-full before:bg-gradient-to-b before:from-gradient before:to-white [.light_&]:divide-grey-light [.light_&]:border-grey-light">
                {footer || (
                  <>
                    <Button
                      data-trackingid={id as string}
                      tabIndex={show ? 0 : -1}
                      link={{ value: { text: primaryText ? primaryText : 'Apply' } }}
                      icon={primaryIcon ?? 'chevron-right-double'}
                      type="action"
                      button
                      onClick={() => props?.primaryClick?.()}
                      className="flex flex-1 justify-center border-0"
                    />
                    <Button
                      data-trackingid={id as string}
                      tabIndex={show ? 0 : -1}
                      link={{ value: { text: secondaryText ? secondaryText : 'Reset' } }}
                      icon={secondaryIcon ?? 'chevron-right-double'}
                      type="action"
                      service="tri-service"
                      button
                      onClick={() => props?.secondaryClick?.()}
                      className="flex flex-1 justify-center border-0"
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </FocusTrap>

      <div
        onMouseUp={() => {
          closeDrawer()
          unlockScroll()
        }}
        className={`fixed right-0 top-0 z-[100] h-full w-full bg-black/50`}
        style={{
          transition: 'opacity 0.5s',
          opacity: `${show ? '1' : '0'}`,
          transform: `${fadeMask ? 'translatex(0)' : 'translatex(100%)'}`,
        }}
      ></div>
    </>
  )
}

export default Drawer
