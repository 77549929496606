import { ComponentRendering, HtmlElementRendering, PlaceholdersData } from '@sitecore-jss/sitecore-jss-nextjs'

import slugify from 'src/utils/slugifyStrings'

type JSONValue = HtmlElementRendering | ComponentRendering | string | number | boolean | SelfRef | Array<JSONValue>

type SelfRef = { [key: string]: JSONValue }

export interface TabData {
  data: Data
}

export interface Data {
  datasource: Datasource
}

export interface Datasource {
  id: string
  name: string
  __typename: string
  children: Children
}

export interface Children {
  results: Result[]
}

export interface Result {
  id: string
  name: string
  __typename: string
  title: Title
  children?: Children
}

type ResultWithChildren = Required<Result>

export interface Title {
  field: Field
}

export interface Field {
  value: string
}

export interface TabGroupSet {
  __typename: string
  name: string
  ancestors: Ancestor[]
  options: string[]
}

interface Ancestor {
  tabName: string
  tabValue: string
}

const DEBUG_TAB_DEPENDENCIES = false

export type AncestorMap = Record<string, Ancestor[]>

/**
 * Traverse the sitecore context and find all the tabs and maintain their ancestors
 * @param node
 * @param level
 * @param ancestors
 * @param children
 * @returns
 */
export function findTabGroups(
  node: JSONValue | undefined,
  level = 0,
  ancestors: Ancestor[] = [],
  children: Ancestor[] = []
): TabGroupSet[] {
  if (!node || level > 50) {
    return []
  }
  let tabs: TabGroupSet[] = []

  if (Array.isArray(node)) {
    node.forEach((childNode) => {
      // console.log('========> array', level)
      tabs = tabs.concat(findTabGroups(childNode, level + 1, ancestors, children))
    })
    if (DEBUG_TAB_DEPENDENCIES && level === 0) {
      console.log('========> TABS', tabs)
    }
    return tabs
  }
  if (typeof node !== 'object' || node === null) {
    return tabs
  }

  let options: string[] = []
  let name: string
  Object.keys(node).forEach((key) => {
    const thisNode = (node as SelfRef)[key]
    if (key === 'componentName' && ['ADFPageTab', 'ADFSectionTab', 'ADFSideTab'].includes(thisNode as string)) {
      const componentFields = (node as ComponentRendering).fields as unknown as TabData
      const datasource = componentFields.data.datasource || {}
      name = datasource.name
      const __typename = datasource.__typename

      options = componentFields.data.datasource.children.results.map((result) => result.name)

      if (DEBUG_TAB_DEPENDENCIES) {
        console.log(`========> STD TAB [${level}]`, thisNode, name, options)
      }
      tabs = tabs.concat([{ __typename, name, ancestors, options }])
      // ADFSideTab has children, but not nested the same way (through placeholders) so just access
      // the children directly and calculate the ancestors
      if (thisNode === 'ADFSideTab' && componentFields.data.datasource.children.results) {
        if (DEBUG_TAB_DEPENDENCIES) {
          console.log(`========> ADFSideTab [${level}]`, componentFields.data.datasource.children.results)
        }
        tabs = tabs.concat(
          componentFields.data.datasource.children.results.reduce((acc, result: ResultWithChildren) => {
            return [
              ...acc,
              // Add in the current result
              {
                __typename: result.__typename,
                name: result.name,
                ancestors: [...ancestors, { tabName: name, tabValue: result.name }],
                options: result.children.results.map((sideTabNav) => sideTabNav.name),
              },
              // Add in one for each child (this could be recursive call, but the structure for SideTabSubItem's is just an array of string)
              ...result.children.results.map((sideTabNav) => ({
                __typename: sideTabNav.__typename,
                name: sideTabNav.name,
                based: sideTabNav,
                ancestors: [
                  ...ancestors,
                  { tabName: name, tabValue: result.name },
                  { tabName: result.name, tabValue: sideTabNav.name },
                ],
                options: [sideTabNav.name],
              })),
            ]
          }, [] as TabGroupSet[])
        )
      }
    } else if (key.substring(0, 'placeholder'.length) === 'placeholder') {
      const index = key.substring(key.length - 1) as unknown as number
      // This kind of sucks, we need to pass tab options down when the key is placeholders, so placeholder_0_x can index them
      // So children is our current tab options, which is then passed along as potential ancestors down the line.
      const newChildren = options.map((option) => ({ tabName: name, tabValue: option }))
      const newAncestors = [...ancestors]

      if (children[index]) {
        newAncestors.push(children[index])
      }

      tabs = tabs.concat(findTabGroups(thisNode, level + 1, newAncestors, newChildren))
      // console.log(`passing ancestor ${level}`, tabs.length, newAncestors, thisNode)
    } else if (
      key === 'componentName' &&
      ['ADFContainer'].includes(thisNode as string) &&
      'placeholders' in node &&
      'DynamicPlaceholderId' in ((node as ComponentRendering).params || {})
    ) {
      if (DEBUG_TAB_DEPENDENCIES) {
        console.log(`========> CONTAINER [${level}]`, thisNode, key)
      }
      const dynamicPlaceholderId = `container-${(node as ComponentRendering).params?.DynamicPlaceholderId as string}`
      tabs = tabs.concat(
        findTabGroups((node.placeholders as PlaceholdersData)?.[dynamicPlaceholderId], level, ancestors, children)
      )
    }
  })

  return tabs
}

/**
 * Convert a tabGroupSet[] to a quick reverse lookup of the ancestor dependencies
 * It should look like this:
 * {
 *  [parentTabName]: [
 *      { name: childTabName, value: parentTabValue }
 *   ]
 * }
 * This is used by the layoutContext's pageState reducer to determine which tabs to clear/retain when a
 * a non-leaf tab has changed. This means the tabs clean up after themselves.
 * This seem counter-intuitive, but when checking you can verify that a parent's current value is correct for
 * *this* tab that we are testing (eg. it's name).
 * @param tabsGroups TabGroupSet[]
 * @returns AncestorMap
 */
export const getTabDependencies = (tabsGroups: TabGroupSet[]) => {
  let ancestorMap = tabsGroups.reduce((acc, tabGroup) => {
    // Build a reverse lookup of the ancestor dependencies
    const tabGroupName = slugify(tabGroup.name)
    return tabGroup.ancestors.reduce(
      (tabAcc, ancestor) => {
        const parentName = slugify(ancestor.tabName)
        return {
          ...tabAcc,
          [parentName]: [
            ...(tabAcc[parentName] ?? []),
            {
              tabName: tabGroupName,
              tabValue: slugify(ancestor.tabValue),
            },
          ],
        }
      },
      {
        ...acc,
      } as AncestorMap
    )
  }, {} as AncestorMap)

  // just make a basic map if there are single dimensional tabs
  if (Object.keys(ancestorMap).length === 0 && tabsGroups.length > 0) {
    console.log('swithing to 1 dimensional mode')
    ancestorMap = tabsGroups.reduce((acc, tabGroup) => {
      return {
        ...acc,
        [slugify(tabGroup.name)]: tabGroup.options.map((option) => ({
          tabName: slugify(option),
          tabValue: '',
        })),
      }
    }, {} as AncestorMap)
  }
  if (DEBUG_TAB_DEPENDENCIES) {
    console.log('========> ANCESTOR MAP', ancestorMap)
  }
  return ancestorMap
}
