import { CSSProperties, useState } from 'react'
import Image, { ImageProps } from 'next/image'
import { twMerge } from 'tailwind-merge'

import { getImagePath } from './getImagePath'

type ImagePriority =
  | {
      lazy?: false
      priority?: true
    }
  | {
      lazy?: true
      priority?: false | never
    }
  | {
      lazy?: never
      priority?: false | never
    }

type ImageFill =
  | {
      fill?: true | undefined
      sizes: string
      width?: number | `${number}`
      height?: number | `${number}`
      /** @deprecated use fill prop instead */
      format?: 'cover'
    }
  | {
      fill?: true | undefined
      sizes?: string
      width: number | `${number}`
      height: number | `${number}`
      /** @deprecated use fill prop instead */
      format?: 'cover'
    }
  | {
      fill: false
      width: number | `${number}`
      height: number | `${number}`
      sizes?: string
      /** @deprecated use fill prop instead */
      format?: 'contain'
    }

type ImageLoaderProps = Pick<
  ImageProps,
  'className' | 'src' | 'alt' | 'unoptimized' | 'onLoadingComplete' | 'quality'
> &
  ImagePriority &
  ImageFill & {
    wrapperClassName?: string
    full?: boolean
    fixed?: boolean
  }

function ImageLoader({
  src,
  className,
  wrapperClassName,
  full,
  fixed,
  format,
  width: w,
  height: h,
  sizes: s,
  fill: f,
  priority: p,
  lazy: l,
  quality: q,
  ...props
}: ImageLoaderProps) {
  const width = Number(w) || undefined
  const height = full || isNaN(Number(h)) ? undefined : Number(h)
  const sizes = s || (width ? `${width}px` : undefined)

  const priority = true || Boolean(p || !l)
  const fill = f !== false || (typeof f === 'undefined' && format === 'cover')
  const aspectRatio = width && height ? `${width}/${height}` : 'auto'
  const unoptimized = typeof src === 'string' && (src.endsWith('.svg') || src.endsWith('.gif'))
  const [loaded, setLoaded] = useState(priority)

  const imageProps: ImageProps = {
    ...props,
    src: getImagePath(src, unoptimized),
    fill,
    sizes: fill ? sizes : undefined,
    height: fill || full ? undefined : height,
    width: fill ? undefined : width,
    priority,
    unoptimized,
    quality: q || 80,
    onLoadingComplete: (img) => {
      setLoaded(true)
      props.onLoadingComplete?.(img)
    },
  }

  return (
    <div
      className={twMerge(
        'image-loader self relative flex w-full items-center justify-center overflow-hidden',
        full ? 'h-full' : fixed ? 'h-[--fixed-height]' : 'h-auto',
        !fixed && 'aspect-[--aspect-ratio]',
        'bg-grey-pale bg-loading-icon bg-[length:120px_120px] bg-center bg-no-repeat',
        wrapperClassName
      )}
      style={
        {
          '--aspect-ratio': aspectRatio,
          '--fixed-height': height,
        } as CSSProperties
      }
    >
      <Image
        className={twMerge(
          fill ? 'object-cover' : 'object-contain',
          loaded ? 'opacity-100' : 'opacity-0',
          'transition-opacity delay-200 duration-200',
          className
        )}
        {...imageProps}
      />
    </div>
  )
}

export default ImageLoader
