import { MouseEventHandler, useState } from 'react'
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import Icon from 'components/front-end/Icons/Icon'
import ServiceTag from 'components/front-end/Tags/ServiceTag'
import { fixAssetHref } from 'utils/fixAssetHref'
import { Service, useService } from 'utils/useService'

export type QuickLinkProps = {
  service?: Service
  link: LinkField
  className?: string
  isServiceTag?: boolean
  isDownload?: boolean
  error?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
}

const QuickLink = ({
  service: defaultService,
  link,
  className,
  isServiceTag = true,
  isDownload = false,
  error = false,
  onClick,
}: QuickLinkProps) => {
  const [isHovering, setIsHovering] = useState(false)
  const service = useService(defaultService)

  if (!link.value.href && !onClick) {
    return null
  }

  const href = fixAssetHref(link.value, isDownload)
  let filename = ''

  try {
    filename = href?.startsWith('http') ? new URL(href).pathname.split('/').pop() ?? '' : ''
  } catch {}

  const colorBorderClasses = {
    'tri-service': 'border-tri-service',
    army: 'border-army',
    'air-force': 'border-air-force',
    navy: 'border-navy',
  }[service]

  return (
    <Link
      href={href}
      target={link.value.target}
      className="block"
      onClick={onClick}
      download={isDownload && filename?.endsWith('.pdf') ? filename : undefined}
    >
      <div
        className={twMerge(
          'my-6 flex items-center justify-between border-l-4 px-4 py-5 text-black sm:my-6 sm:flex-row sm:items-center sm:px-8 sm:py-6 md:my-12 [.dark_&]:text-white [.light_&]:bg-white-off',
          className,
          colorBorderClasses,
          isHovering && 'cursor-pointer',
          error && '!border-error',
          `icon-hover-${isHovering}`
        )}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="pr-6">
          {isServiceTag && (
            <div className="mb-2 inline-block">
              <ServiceTag service={service} isCard={true} />
            </div>
          )}
          <p className="mbody-bold [.dark_&]:text-white [.light_&]:text-black">{link.value.title}</p>
          <p className="sbody mt-1 [.dark_&]:text-white [.light_&]:text-black">{link.value.text}</p>
        </div>
        {!error && (
          <div className="mt-4 sm:mt-0">
            <Icon
              name={linkIconName(link)} // Use the determined icon name
              type="stroke"
              hover
              width={24}
              height={24}
              className="[.dark_&]:text-white [.light_&]:text-black"
            />
          </div>
        )}
      </div>
    </Link>
  )
}

// Determine the icon name based on the link type
const linkIconName = (link: LinkField) => {
  const iconMappings = {
    downloadable: 'download',
    drawer: 'arrow-east',
    internal: 'chevron-right-double',
    external: 'open-new',
  } as const

  if (!link.value) {
    return iconMappings.drawer
  }

  if (link.value.href === 'download' || link.value.href?.endsWith('.pdf') || link.value.href?.endsWith('.docx')) {
    return iconMappings.downloadable
  } else if (link.value.linktype === 'external') {
    return iconMappings.external
  } else if (link.value.target === '_blank') {
    return iconMappings.external
  } else if (link.value.href?.startsWith('/') || link.value.href?.startsWith('#')) {
    return iconMappings.internal
  } else {
    return iconMappings.drawer
  }
}
export default QuickLink
