import { Field, ImageField, PlaceholdersData } from '@sitecore-jss/sitecore-jss-nextjs'
import { ComponentProps } from 'lib/component-props'

import Button from 'components/front-end/Buttons/Button'
import Icon from 'components/front-end/Icons/Icon'

interface Fields {
  ErrorText: Field<string>
  ErrorHeading: Field<string>
  ErrorImage: ImageField
  ErrorTitle: Field<string>
}

export type ErrorProps = ComponentProps & {
  fields: Fields
  placeholders?: PlaceholdersData
}
const ADFErrorMessage = (props: ErrorProps) => {
  return (
    <div className="dark flex flex-1 flex-col justify-center" data-cols="12">
      {props.fields && (
        <div className="-translate-y-20 pb-10 pt-20">
          <h1 className="h1 flex items-center">
            <span className="text-[2.5em] text-white">{props.fields.ErrorTitle.value}</span>
            <span className="text-[2.5em] text-tri-service">
              <Icon name="chevron-right-triple" className="!h-[1em] !w-[1em]" type="stroke" width={120} height={120} />
            </span>
          </h1>
          {/* using ems to keep line break consistent with clamp */}
          <div
            className="max-w-[14em] font-uniform-condensed text-lg font-medium"
            dangerouslySetInnerHTML={{ __html: props.fields.ErrorText.value }}
          />
          <Button
            data-trackingid={props.rendering.uid as string}
            className="mt-16"
            icon="chevron-right-double"
            type="primary"
            service="tri-service"
            link={{ value: { href: '/', text: 'Return to Homepage' } }}
          />
        </div>
      )}
    </div>
  )
}

export default ADFErrorMessage
