import { twJoin, twMerge } from 'tailwind-merge'

export type Tag = {
  text: string
  type?: 'default' | 'closed' | 'priority'
  className?: string
}

type StandardTagProps = Tag

const StandardTag = ({ text, type = 'default', className }: StandardTagProps) => {
  let variantTagClasses = ''
  let variantTextClasses = ''

  switch (type) {
    case 'closed':
      variantTagClasses = 'bg-error'
      variantTextClasses = 'text-white'
      break

    case 'priority':
      variantTagClasses = '[.dark_&]:bg-[#f8f8f8] [.light_&]:bg-black'
      variantTextClasses = '[.dark_&]:text-black [.light_&]:text-white'
      break

    case 'default':
      variantTagClasses = 'bg-grey-pale [.dark_&]:bg-grey-dark [.light_&]:bg-grey-pale'
      variantTextClasses = 'text-black [.dark_&]:text-white [.light_&]:text-black'
      break

    default:
      variantTagClasses = 'bg-grey-pale [.dark_&]:bg-grey-dark [.light_&]:bg-grey-pale'
      variantTextClasses = 'text-black [.dark_&]:text-white [.light_&]:text-black'
  }

  return (
    <div className={twMerge('flex cursor-default items-center px-2 pb-[5.5px] pt-1.5', variantTagClasses, className)}>
      <div className={twJoin('xstag', variantTextClasses)}>{text}</div>
    </div>
  )
}

export default StandardTag
